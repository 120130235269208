import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Menu, Row, Spin, Dropdown, Checkbox, Icon, Empty, Col, Select, Input } from 'antd';
import ProjectListComponent from './components/ProjectListComponent';
// import { Link } from 'react-router-dom';
import { deleteProject, fetchMarketplaceProjects, fetchProjectTemplates } from './duck/ProjectActions';
import { installApp, updateMarketplaceApp } from '../marketplaceApp/duck/MarketplaceAppActions';
// import { getAvatarColor } from '../utils/commonFunctions';

class ProjectListContainer extends React.Component {

  render () {
		const { marketplaceProjects, templates } = this.props;
    return (
      <ProjectListComponent
        marketplaceProjects={marketplaceProjects}
        templates={templates}
        loggedInuser={this.props.loggedInuser}
        fetchMarketplaceProjects={this.props.fetchMarketplaceProjects}
        fetchProjectTemplates={this.props.fetchProjectTemplates}
        installApp={this.props.installApp}
        installAppSuccess={this.props.installAppSuccess}
        installAppFailed={this.props.installAppFailed}
        updateMarketplaceApp={this.props.updateMarketplaceApp}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const projects = state.project.projects;
  return {
    loggedInuser: state.currentUser && state.currentUser.data && state.currentUser.data.data,
    marketplaceProjects: state.project.marketplaceProjects && state.project.marketplaceProjects.map(project => state.marketplaceApp.apps[project]),
    templates: state.project.templates,
    installAppSuccess: state.marketplaceApp.installAppSuccess,
    installAppFailed: state.marketplaceApp.installAppFailed,
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
    deleteProject: (id) => dispatch(deleteProject(id)),
    installApp: (id) => dispatch(installApp(id)),
    fetchMarketplaceProjects: (params) => dispatch(fetchMarketplaceProjects(params)),
    fetchProjectTemplates: (params) => dispatch(fetchProjectTemplates(params)),
    updateMarketplaceApp: (marketplaceApp) => dispatch(updateMarketplaceApp(marketplaceApp)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectListContainer);
