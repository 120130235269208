import React, { Component } from 'react'
import {Row, Input, Select, DatePicker, InputNumber, Divider, Dropdown} from 'antd'
import moment from 'moment'
import RGL, { WidthProvider } from "react-grid-layout";
import Textbox from './fieldComponents/textbox';
import Label from './fieldComponents/label';
import Number from './fieldComponents/number';
import TextArea from './fieldComponents/textarea';
import DropdownSelect from './fieldComponents/dropdown';
import SingleSelectDate from './fieldComponents/singleSelectDate'

const ReactGridLayout = WidthProvider(RGL);

export default class FormSections extends Component {

    addValue = (fieldId, val) => {
        let activeTask = Object.assign({}, this.props.activeTask)
        // console.log(activeTask)
        // activeTask.fields = activeTask..fields
        if(activeTask.fields){
            if(activeTask.fields[fieldId]){
                activeTask.fields[fieldId] = {value: {row_id: val}}
            }else{
                activeTask.fields[fieldId] = {
                    value : {
                        row_id : val
                    }
                }
            }
        }else{
            activeTask.fields = {
                [fieldId] : {
                    value : {
                        row_id : val
                    }
                }
            }
        }
        this.props.updateTask(activeTask)
    }

    render() {
        let {section, sectionFields, activeTask} = this.props
        return (
            <Row>
                {
                    section &&
                        <>
                            {
                                section.section_name.trim().toLowerCase() !== 'untitled section' &&
                                <>
                                    <Row className='f-20' style={{lineHeight: '23px',color: 'rgb(68, 86, 108)',padding: '5px 10px'}}>{section.section_name}</Row>
                                    <Row style={{fontSize:'12px',lineHeight: '23px',color: 'rgb(68, 86, 108)',padding: '0 10px'}}>{section.section_description}</Row>
                                </>
                            }
                            <ReactGridLayout
                                rowHeight		= {10}
                                cols			= {24}
                                isDraggable     = {false}
                                isResizable     = {false}
                                width			= {10}
                                layout			= {section.section_layout}
                            >
                                {
                                    sectionFields.map(field=>{
                                        switch(field.dataType){
                                            case 'url' :
                                            case 'telephone' :
                                            case 'text' :
                                                return (
                                                    <div className='item gridDraghandler' key={field.id}>
                                                        <Label
                                                            name      = {field.alias_name ? field.alias_name : field.name}
                                                            mandatory = {field.mandatory}
                                                        />
                                                        <Textbox
                                                            activeTask  = {activeTask}
                                                            field       = {field}
                                                            addValue    = {this.addValue}
                                                        />
                                                    </div>
                                                )
                                            case 'number' :
                                                return (
                                                <div className='item gridDraghandler' key={field.id}>
                                                    <Label
                                                        name      = {field.alias_name ? field.alias_name : field.name}
                                                        mandatory = {field.mandatory}
                                                    />
                                                    <br/>
                                                    <Number
                                                        activeTask  = {activeTask}
                                                        field       = {field}
                                                        addValue    = {this.addValue}
                                                    />
                                                </div>
                                            )
                                            case 'dropdown' :
                                                return (
                                                    <div className='item gridDraghandler' key={field.id}>
                                                        <Label
                                                            name      = {field.alias_name ? field.alias_name : field.name}
                                                            mandatory = {field.mandatory}
                                                        />
                                                        <DropdownSelect 
                                                            activeTask  = {activeTask}
                                                            field       = {field}
                                                            addValue    = {this.addValue}
                                                        />
                                                    </div>
                                                )
                                            case 'textarea' :
                                                return (
                                                    <div className='item gridDraghandler' key={field.id}>
                                                        <Label
                                                            name      = {field.alias_name ? field.alias_name : field.name}
                                                            mandatory = {field.mandatory}
                                                        />
                                                        <TextArea
                                                            activeTask  = {activeTask}
                                                            field       = {field}
                                                            addValue    = {this.addValue}
                                                        />
                                                    </div>
                                                )
                                            case 'date' :
                                                return (
                                                    <div className='item gridDraghandler' key={field.id}>
                                                        <Label
                                                            name      = {field.alias_name ? field.alias_name : field.name}
                                                            mandatory = {field.mandatory}
                                                        />
                                                        <br/>
                                                        <SingleSelectDate
                                                            activeTask  = {activeTask}
                                                            field       = {field}
                                                            addValue    = {this.addValue}
                                                        />
                                                        
                                                    </div>
                                                )

                                            default: break;
                                        }
                                    })
                                }
                            </ReactGridLayout>
                        </>
                }
            </Row>
        )
    }
}
