let BASE_URL, url;
url =  window.location.hostname;

switch(url){
    case 'app-admin.procezo.com':
        // BASE_URL  = 'https://zeus.procezo.com';
        BASE_URL  = 'https://tzar.procezo.com';
        break;
    case 'procezo-dev-admin.ibism.com':
        BASE_URL  = 'https://stark.ibism.com';
        break;
    // case 'localhost':
    //     BASE_URL = 'http://localhost:4343';
    //     break;
    default: BASE_URL = 'https://stark.ibism.com';// procezo dev
}

const API_BASE_URL = `${BASE_URL}/api/v1/`;

let appUrl = {
    SOCKET_URL               : BASE_URL,
    PAGE_URL                 : API_BASE_URL + 'pages',
    PROCESS_URL              : API_BASE_URL + 'processes',
    FIELD_URL                : API_BASE_URL + 'fields',
    TAGS_URL                 : API_BASE_URL + 'tags',
    REPORTS                  : API_BASE_URL + 'reports',
    REPORTS_CONSUME          : API_BASE_URL + 'reports-consume/page',
    BILLING                  : API_BASE_URL + 'billing',
    USER_DETAILS             : API_BASE_URL + 'user/',
    ACCOUNT_ACTIVATE         : API_BASE_URL + 'activate',
    PEOPLE_URL               : API_BASE_URL + 'users',
    TEAM_URL                 : API_BASE_URL + 'teams',
    USER_ROLE                : API_BASE_URL + 'roles',
    ACCOUNTS                 : API_BASE_URL + 'accounts',
    PROFILE                  : API_BASE_URL + 'profile',
    ANALYTICS                : API_BASE_URL + 'analytics',
    REQUESTANALYTICS         : API_BASE_URL + 'request-analytics',
    ATTACHMENTS              : API_BASE_URL + 'attachments',
    CATEGORIES               : API_BASE_URL + 'categories',
    PROJECTS_URL             : API_BASE_URL + 'projects',
    MARKETPLACE_URL          : API_BASE_URL + 'apps',
    MARKETPLACE_INSTALL_URL  : API_BASE_URL + 'apps-install',
    DATASET_URL              : API_BASE_URL + 'datasets',
    RECORD_URL               : API_BASE_URL + 'records',
    ORGANISATION_URL         : API_BASE_URL + 'organisations/join-organisation-list'
    // FILTER_URL               : API_BASE_URL,
    // LOGIN_URL                : API_BASE_URL + 'login',
    // SIGNUP_URL               : API_BASE_URL + 'signup',
    // GOOGLE_URL               : API_BASE_URL + 'googlelogin',
    // FORGOT_URL               : API_BASE_URL + 'forgotpass',
    // THREAD_URL               : API_BASE_URL + 'threads',
    // TEMPLATE_URL             : API_BASE_URL + 'templates',
    // CARD_INFO                : API_BASE_URL + 'billing/paymentDetails',
    // PAY_BILL                : API_BASE_URL + 'billing/payInvoice',
    // INVOICES                 : API_BASE_URL + 'billing/invoices',
    // WORKFLOW_URL             : API_BASE_URL + 'workflows',
    // ACTIVITY_URL             : API_BASE_URL + 'activities',
    // DELETE_WORKFLOW_URL      : API_BASE_URL + 'workflow/',
    // TOKEN_VERIFY_URL         : API_BASE_URL + 'verification',
    // TOKEN_RESEND_URL         : API_BASE_URL + 'resend-verification-token',
    // NEW_PASSWORD_URL         : API_BASE_URL + 'setforgotpass',
    // SET_PASSWORD_URL         : API_BASE_URL + 'setpassauth',
    // TODOLIST_URL             : API_BASE_URL + 'todoList',
    // FETCH_TEAMS              : API_BASE_URL + 'teams',
    // EVENTS_URL               : API_BASE_URL + 'events',
    // NOTIFICATIONS_URL        : API_BASE_URL + 'notifications',
    // READ_NOTIFICATIONS_URL   : API_BASE_URL + 'notificationread',
    // BUSINESS_OBJECT_TEMPLATE : API_BASE_URL + 'business-object-templates',
    // BUSINESS_OBJECT          : API_BASE_URL + 'business-objects',
    // CUSTOM_NOTIFICATIONS     : API_BASE_URL + 'custom_notification_templates',
    // WEBHOOKS                 : API_BASE_URL + 'webhooks',
    // SEARCH_URL               : API_BASE_URL + 'search',
    // NOTES                    : API_BASE_URL + 'notes',
    // IS_TEMPLATE_BUSY         : API_BASE_URL + 'templates/check-for-templates-in-workflow/',
    // MARKET_PLACE             : API_BASE_URL + 'market-place',
    // MARKET_PLACE_TEMPLATES   : API_BASE_URL + 'market-place?type=template',
    // MARKET_PLACE_WORKFLOWS   : API_BASE_URL + 'market-place?type=workflow',
    // MARKET_PLACE_BO          : API_BASE_URL + 'market-place?type=businessObject',
    // MARKET_PLACE_REPORTS     : API_BASE_URL + 'market-place?type=reports',
};

export default appUrl;
