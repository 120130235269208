import React, { Component } from 'react'
import {connect} from 'react-redux';
import {Row, Col, Icon, Input} from 'antd';
// import AddNewButton from '../../utils/addNewButton'
import FieldTypeList from './fieldTypeList'
import Section from './section'
import {guidGenerator} from '../../utils/commonFunctions'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
// import CreateFieldModal from './createFieldModal';
import PropertiesModal from './propertiesModal'

class FormBuilderContainer extends Component {

    state = {
        // showModal   : false,
        searchText  : '',
        showPropertiesModal : false,
        propertiesModalPos : {top : 0, left : 0},
        activeField : null
    }

    // toggleModal = (val) => {
    //     this.setState({showModal:val})
    // }
    
    togglePropertiesModal = (pos, val, field) => {
        if(pos){
            this.setState({showPropertiesModal: val, propertiesModalPos: pos, activeField: field})
        }else{
            this.setState({showPropertiesModal: val, activeField: null})
        }
    }
    
    addNewSection = (id) => {
        let data = [...this.props.sections]
        let _idx = data.findIndex(s=>s.section_id === id)
        let obj  = {
            section_id          : guidGenerator(),
            section_name        : 'Untitled Section',
            section_description : 'Section Information',
            section_layout      : []
        }
        data.splice(_idx + 1, 0, obj)
        this.props.updateSections(data)
    }

    copySection = (id) => {
        let data = [...this.props.sections]
        let _sec = data.find(s=>s.section_id === id)
        let _idx = data.findIndex(s=>s.section_id === id)
        let obj  = {
            section_id          : guidGenerator(),
            section_name        : 'Copy of '+_sec.section_name,
            section_description : _sec.section_description,
            section_layout      : _sec.section_layout
        }
        data.splice(_idx + 1, 0, obj)
        this.props.updateSections(data)
    }

    sectionName = (val, id) => {
        let data = [...this.props.sections]
        let _sec = data.find(s=>s.section_id === id)
        _sec.section_name = val;
        this.props.updateSections(data)
    }
    
    sectionDescription = (val, id) => {
        let data = [...this.props.sections]
        let _sec = data.find(s=>s.section_id === id)
        _sec.section_description = val;
        this.props.updateSections(data)
    }

    onLayoutChange = (id, layout) => {
        let data = JSON.parse(JSON.stringify(this.props.sections))
        // let data = [...this.props.sections]
        let _sec = data.find(s=>s.section_id === id)
        _sec.section_layout = layout;
        this.props.updateSections(data)
    }

    searchFields = (e) => {
        this.setState({searchText:e.target.value})
    }

    render() {
        let {fields, isProject} = this.props;
        fields       = (fields.data && fields.data.data) ? fields.data.data : []
        return (
            <DndProvider backend={HTML5Backend}>
                <Row style={{marginTop:'60px'}}>
                    <Col span={4}>
                        <Row style={{position:"fixed",width:'250px'}}>
                            <Row className='mb-20'>
                                <Col span={24}>
                                    <Input
                                        placeholder="Search fields"
                                        prefix={<Icon type="search" style={{fontSize:'20px', color: 'rgba(0,0,0,.25)' }} />}
                                        size='large'
                                        className='input-box searchbox'
                                        onKeyUp = {this.searchFields}
                                    />
                                </Col>
                                {/* <Col span={6} style={{textAlign:'right'}}>
                                    <AddNewButton trigger={e=>this.toggleModal(true)}/>
                                </Col> */}
                            </Row>
                            <FieldTypeList fields = {fields} searchText={this.state.searchText} />
                        </Row>
                    </Col>
                    <Col span={20} style={{height: 'calc(100vh - 106px)', overflow: 'auto'}}>
                        <Row type='flex' justify='center' style={{height:'300px',marginTop:'20px'}}>
                            <Row style={{background:'#fff',width:'80%',borderRadius: '5px',padding:'20px',boxShadow: '0px 8px 16px rgba(169, 194, 209, 0.1), 0px 0 32px rgba(169, 194, 209, 0.15)'}}>
                                {
                                    this.props.sections.map(section=>{
                                        return  <Section 
                                                    key                 = {section.section_id}
                                                    section             = {section} 
                                                    addNewSection       = {this.addNewSection} 
                                                    // deleteSection       = {this.deleteSection}
                                                    sectionName         = {this.sectionName}
                                                    sectionDescription  = {this.sectionDescription}
                                                    copySection         = {this.copySection}
                                                    togglePropertiesModal= {this.togglePropertiesModal}
                                                    onLayoutChange      = {this.onLayoutChange}
                                                    allFields           = {this.props.allFields}
                                                    updateFields        = {this.props.updateFields}
                                                    isProject           = {isProject}
                                                    sections            = {this.props.sections}
                                                    steps               = {this.props.steps}
                                                    updateSteps         = {this.props.updateSteps}
                                                    updateSections      = {this.props.updateSections}
                                                />                                
                                    })
                                }
                            </Row>
                        </Row>
                        {/* <CreateFieldModal
                            toggleModal = {this.toggleModal}
                            showModal   = {this.state.showModal}
                        /> */}
                        {
                            this.state.showPropertiesModal &&
                                <PropertiesModal
                                    allFields               = {this.props.allFields}
                                    updateFields            = {this.props.updateFields}
                                    position                = {this.state.propertiesModalPos} 
                                    togglePropertiesModal   = {this.togglePropertiesModal}
                                    activeField             = {this.state.activeField}
                                />
                        }
                    </Col>
                </Row>
            </DndProvider>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        fields : state.fields
    }
}

export default connect(mapStateToProps)(FormBuilderContainer);
