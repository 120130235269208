import React, { Component } from 'react'
import {Row} from 'antd'
import Conditions from '../../formBuilder/conditions'
import moment from 'moment'

const operatorSymbol = {
    'is' : '==',
    'is not' : '!=',
    'contains' : 'in',
    'is filled out' : '!=',
    'is not filled out' : '==',
    'is greater than' : '>',
    'is less than' : '<',
    'is greater than or equal to' : '>=',
    'is less than or equal to' : '<=',
    'has atleast one file' : "!=",
    'has no files' : "==",
}

export default class Execution extends Component {

    state = {
        active : this.props.activeStep.execution
    }

    // componentDidUpdate(prevProps){
    //     if(this.props.activeStep !== prevProps.activeStep){
    //         this.setState({active:this.props.activeStep.execution})
    //     }
    // }

    change = (val) => {
        this.setState({active:val})
        let steps   = Object.assign({},this.props.steps)
        steps[this.props.activeStep.id].execution = val
        this.props.updateSteps(steps)
    }

    conditionObject = () => {
        let allFields   = Object.assign({}, this.props.allFields)

        let drawData = {
            active_control   : (Object.keys(allFields).length > 0) ? allFields[Object.keys(allFields)[0]].id : '',    //allFields[0].id : '',
            active_condition : (Object.keys(allFields).length > 0) ? (allFields[Object.keys(allFields)[0]].dataType === 'file' ? 'has no files' : 'is filled out') : '',
            value            : '',
            type             : (Object.keys(allFields).length > 0) ? allFields[Object.keys(allFields)[0]].dataType : '',
        }
        let logic = {}
        if(drawData.active_condition === 'contains'){
            logic = {            
                [operatorSymbol[drawData.active_condition]] : [
                    (drawData.value) ? drawData.value : null,
                    {
                        var : drawData.active_control
                    }
                ]
            }
        }else{
            logic = {            
                [operatorSymbol[drawData.active_condition]] : [
                    {
                        var : drawData.active_control
                    },
                    (drawData.value) ? drawData.value : null,
                ]
            }
        }
        
        let obj = {
            drawData,
            logic
        }
        return obj
    }

    addOrCondition = () => {
        let step = Object.assign({}, this.props.activeStep)

        if(step.dependency.draw_data.or && step.dependency.draw_data.or.length > 0){
            step.dependency.draw_data.or.push({
                and : [
                    this.conditionObject().drawData
                ]
            })
        }else{
            let or = []
            or.push({
                and : [
                    this.conditionObject().drawData
                ]
            })
            step.dependency.draw_data['or'] = or
        }
        if(step.dependency.logic.or && step.dependency.logic.or.length > 0){
            step.dependency.logic.or.push({
                and : [
                    this.conditionObject().logic
                ]
            })
        }else{
            let or = []
            or.push({
                and : [
                    this.conditionObject().logic
                ]
            })
            step.dependency.logic['or'] = or
        }
        
        //Code redundancy here, but its needed to synchronize affects and dependency
        let steps   = Object.assign({},this.props.steps)
        delete steps[step.id]
        steps[step.id] = step;

        this.props.updateSteps(steps)
        // this.setState({activeStep: step})
    }

    addAndCondition = (index) => {
        let step = Object.assign({}, this.props.activeStep)
        step.dependency.draw_data.or[index].and.push(this.conditionObject().drawData)
        step.dependency.logic.or[index].and.push(this.conditionObject().logic)
        
        let steps   = Object.assign({},this.props.steps)
        delete steps[step.id]
        steps[step.id] = step;

        this.props.updateSteps(steps)
        // this.setState({activeStep: step})
    }

    handleField = (fieldId, prevFieldId, orIndex, andIndex) => {
        // console.log(fieldId, prevFieldId, orIndex, andIndex)
        let steps      = Object.assign({}, this.props.steps)
        let step       = Object.assign({}, this.props.activeStep)
    
        let allFields   = {...this.props.allFields}
        let found       = allFields[fieldId]
        
        step.dependency.draw_data.or[orIndex].and[andIndex].active_control = fieldId
        step.dependency.draw_data.or[orIndex].and[andIndex].type           = found.dataType
        
        let con = step.dependency.draw_data.or[orIndex].and[andIndex].active_condition
        step.dependency.logic.or[orIndex].and[andIndex][operatorSymbol[con]][con === 'contains' ? 1 : 0].var = fieldId

        delete steps[step.id]
        steps[step.id] = step
        this.props.updateSteps(steps)
        // this.setState({activeStep: step})
    }

    handleOperator = (val, orIndex, andIndex) => {
        // console.log(val, orIndex, andIndex)
        let step = Object.assign({}, this.props.activeStep)
        step.dependency.draw_data.or[orIndex].and[andIndex].active_condition = val
        
        if(val === 'contains'){
            step.dependency.logic.or[orIndex].and[andIndex] = {
                [operatorSymbol[val]] : [
                    step.dependency.draw_data.or[orIndex].and[andIndex].value ? step.dependency.draw_data.or[orIndex].and[andIndex].value : null,
                    {var: step.dependency.draw_data.or[orIndex].and[andIndex].active_control}
                ]
            }
        }else{
            step.dependency.logic.or[orIndex].and[andIndex] = {
                [operatorSymbol[val]] : [
                    {var: step.dependency.draw_data.or[orIndex].and[andIndex].active_control},
                    step.dependency.draw_data.or[orIndex].and[andIndex].value ? step.dependency.draw_data.or[orIndex].and[andIndex].value : null
                ]
            }
        }
        
        let steps   = Object.assign({},this.props.steps)
        delete steps[step.id]
        steps[step.id] = step;

        this.props.updateSteps(steps)
        // this.setState({activeStep: step})
    }

    handleValue = (e, orIndex, andIndex, type = null) => {
        let step = Object.assign({}, this.props.activeStep)
        let val;
        if(type){
            if(type === 'number') val       = e
            else if (type === 'date') val   = moment(e).format('DD/MM/YYYY')
            else if (type === 'text') val   = e.target.value
        }else{
            val = e.target.value
        }
        step.dependency.draw_data.or[orIndex].and[andIndex].value = val

        let con = step.dependency.draw_data.or[orIndex].and[andIndex].active_condition
        step.dependency.logic.or[orIndex].and[andIndex][operatorSymbol[con]][con === 'contains' ? 0 : 1] = val

        // console.log(val, orIndex, andIndex, type)
        
        let steps   = Object.assign({},this.props.steps)
        delete steps[step.id]
        steps[step.id] = step;

        this.props.updateSteps(steps)
        // this.setState({activeStep: step})
    }

    deleteAndCondition = (affectId, orIndex, andIndex) => {
        let step = Object.assign({}, this.props.activeStep)
        
        step.dependency.draw_data.or[orIndex].and.splice(andIndex, 1)
        if(step.dependency.draw_data.or[orIndex].and.length === 0){
            step.dependency.draw_data.or.splice(orIndex, 1)
            step.dependency.logic.or.splice(orIndex, 1)
        }
        if(step.dependency.draw_data.or.length === 0){
            step.dependency.draw_data = {}
            step.dependency.logic = {}
        }
        
        let steps   = Object.assign({},this.props.steps)
        delete steps[step.id]
        steps[step.id] = step;

        this.props.updateSteps(steps)
        // this.setState({activeStep: step})
    }

    render() {
        return (
            <>
                <Row style={{fontSize:'30px',color:'#44566C',fontWeight:'500'}} className='mt-20 mb-20'>
                    When will this task happen?
                </Row>
                <Row type='flex' className='custom-switch mb-20'>
                    <div className={this.state.active === 'user' ? 'active' : ''} style={{minWidth:'60px',textAlign:'center'}} onClick={e=>this.change('user')}>User</div>
                    <div className={this.state.active === 'auto' ? 'active' : ''} style={{minWidth:'60px',textAlign:'center'}} onClick={e=>this.change('auto')}>Auto</div>
                </Row>
                {
                    this.state.active === 'user' 
                    ?
                        <Row>
                            Doer of the previous task gets to decide if this task will be created or not.
                        </Row>
                    :
                        <>
                            <Row className='mb-20' style={{color:'#697789'}}>When does this task happen ?</Row>
                            <Row className='pr-20 pb-20' style={{border:'1px solid #D9DDE1',borderRadius:5}}>
                                <Conditions
                                    allFields           = {this.props.allFields}
                                    activeField         = {this.props.activeStep}
                                    addOrCondition      = {this.addOrCondition}
                                    addAndCondition     = {this.addAndCondition}
                                    deleteAndCondition  = {this.deleteAndCondition}
                                    handleField         = {this.handleField}
                                    handleOperator      = {this.handleOperator}
                                    handleValue         = {this.handleValue}
                                />
                            </Row>
                        </>
                }
            </>
        )
    }
}
