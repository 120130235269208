import React from 'react';
import 'antd/dist/antd.css';
import AppLayout from "./auth/AppLayout";

// This file is not being used yet
function App() {
    return (
       <AppLayout />
  );
}

export default App;
