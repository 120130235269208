import {put, takeLatest,call,fork,race,take,retry} from 'redux-saga/effects';
import appUrl from '../../constants/AppUrl';
import axios from 'axios';
import {defaultConfig} from '../../config/Axios';
import ACTIONS from './TeamsActionTypes';
import {showNotification} from '../../utils/commonFunctions'

function* retrySaga({apiRequest,data, successType, failType, error}) {
    // https://gist.github.com/fgilio/230ccd514e9381fafa51608fcf137253
    if(error.message === 'Network Error') {
        // if internet was disconnected
        try {
            const {noOfAttempts,interval} = defaultConfig.retry;
            // Retry api in every 1 second, till 20 attempts
            let teams = yield retry(noOfAttempts, interval, apiRequest, data);
            if (teams.data.status) {
                yield put({type: failType, payload: teams.data})
            }else{
                yield put({type: successType, payload: teams.data})
            }
        } catch (error) {
            yield put({type: failType, payload: defaultConfig.exception})
        }
    }else{
        console.log(error)
        yield put({type: failType, payload: defaultConfig.exception})
    }
}

const fetchAPI = (params) => axios({
    method: 'GET',
    url:appUrl.TEAM_URL,
    params
})

const createAPI = (data) => axios({
    method:'POST',
    url: appUrl.TEAM_URL,
    data: {data: {attributes: data}}
})

const updateAPI = ({id,data}) => axios({
    method:'PUT',
    url: appUrl.TEAM_URL + `/${id}`,
    data: {data: {attributes: data}}
})

const deleteAPI = (id) => axios({
    method: 'DELETE',
    url: appUrl.TEAM_URL+'/'+id,
})

function* fetchTeams (action){
    try {
        let teams = yield call(fetchAPI,action.payload);
        if(teams.data.status) {
            // response may be like invaid token.
            yield put({type: ACTIONS.FETCH_FAIL, payload: teams.data});
        }else{
            yield put({type: ACTIONS.FETCH_SUCCESS, payload: teams.data});
        }
    } catch (error) {
        yield call(retrySaga,{
            apiRequest:fetchAPI,
            data:action.payload,
            successType:ACTIONS.FETCH_SUCCESS,
            failType:ACTIONS.FETCH_FAIL,
            error
        });
    }
}

function* fetchMoreTeams (action) {
    try {
        let teams = yield call(fetchAPI, action.payload);
        if(teams.data.status) {
            yield put({type: ACTIONS.FETCH_MORE_FAIL, payload: teams.data});
        }else{
            yield put({type: ACTIONS.FETCH_MORE_SUCCESS, payload: teams.data});
        }
    } catch (error) {
        yield call(retrySaga, {
            apiRequest: fetchAPI,
            data: action.payload,
            successType: ACTIONS.FETCH_MORE_SUCCESS,
            failType: ACTIONS.FETCH_MORE_FAIL,
            error
        })
    }
}

function* deleteTeam (action) {
    try {
        const team = yield call(deleteAPI, action.payload);
        if(team.data.status) {
            showNotification('error',team.data.message || team.data.meta.message);
            yield put({type: ACTIONS.DELETE_FAIL, payload: team.data});
        }else{
            showNotification('success','Team deleted successfully');
            yield put({type: ACTIONS.DELETE_SUCCESS, payload: team.data});
        }
    } catch (error) {
        yield call(retrySaga, {
            apiRequest: deleteAPI,
            data: action.payload,
            successType: ACTIONS.DELETE_SUCCESS,
            failType: ACTIONS.DELETE_FAIL,
            error
        })
    }
}

function* updateTeam (action) {
    try {
        const team = yield call(updateAPI, action.payload);
        if(team.data.status) {
            showNotification('error',team.data.message || team.data.meta.message);
            yield put({type: ACTIONS.UPDATE_FAIL, payload: team.data});
        }else{
           showNotification('success','Team updated successfully');
            yield put({type: ACTIONS.UPDATE_SUCCESS, payload: team.data});
        }
    } catch (error) {
        yield call(retrySaga, {
            apiRequest: updateAPI, data: action.payload,
            successType: ACTIONS.UPDATE_SUCCESS,
            failType: ACTIONS.UPDATE_FAIL,
            error
        })
    }
}

function* createTeam (action) {
    try {
        const team = yield call(createAPI, action.payload);
        // wait until api is finished
        // Then check if there is some problem in team's data, like teams exists,
        if(team.data.status) {
            showNotification('error',team.data.message || team.data.meta.message);
            yield put({type: ACTIONS.CREATE_FAIL, payload: team.data});
        } else{
            showNotification('success','Team created successfully');
            yield put({type: ACTIONS.CREATE_SUCCESS, payload: team.data});
        }
    } catch (error) {
        // if there is any error, check if the error is due to disconnected net.
        yield call(retrySaga, {
            apiRequest: createAPI, data: action.payload,
            successType: ACTIONS.CREATE_SUCCESS,
            failType: ACTIONS.CREATE_FAIL,
            error
        })
    }
}

//  **** START POINT - ACTION WATCHER  ***
export default function* teamsSaga(){
    yield takeLatest(ACTIONS.FETCH_REQUEST, fetchTeams);
    // yield takeLatest(ACTIONS.FETCH_ONE_REQUEST, fetchOneTeam);
    yield takeLatest(ACTIONS.FETCH_MORE_REQUEST, fetchMoreTeams);
    yield takeLatest(ACTIONS.CREATE_REQUEST, createTeam);
    yield takeLatest(ACTIONS.DELETE_REQUEST, deleteTeam);
    yield takeLatest(ACTIONS.UPDATE_REQUEST, updateTeam);
}