import React, { Component } from 'react'
import {Row} from 'antd'

export default class Wwitch extends Component {

    state = {
        active : 'readonly'
    }

    componentDidMount(){
        if(this.props.properties.show){
            this.setState({active: this.props.properties.editable ? 'editable' : 'readonly'})
        }else{
            this.setState({active:'hidden'})
        }
    }

    change = (val) => {
        this.setState({active:val})
        let obj = {show:true,editable:false}
        if(val === 'hidden'){
            obj.show = false
        }else{
            obj.editable = val === 'editable' ? true : false
        }
        this.props.setFieldProperties(obj, this.props.id, this.props.isSection)
    }

    render() {
        // console.log(this.props.properties)
        // if(!this.props.properties.show){

        // }
        return (
            <Row type='flex' className='custom-switch'>
                <div className={this.state.active === 'readonly' ? 'active' : ''} onClick={e=>this.change('readonly')}>Read-Only</div>
                <div className={this.state.active === 'editable' ? 'active' : ''} onClick={e=>this.change('editable')}>Editable</div>
                <div className={this.state.active === 'hidden' ? 'active' : ''} onClick={e=>this.change('hidden')}>Hidden</div>
            </Row>
        )
    }
}
