import React from 'react';
import {Row, Button, Input, Select, Col, Modal, Avatar} from 'antd';
import {isvalidName} from "../../utils/validationFunctions";
import {showNotification, getAvatarColor, getNameInitials} from "../../utils/commonFunctions";

const { TextArea } = Input;
const {Option} = Select;
let inputData = {};
const viewTeam = (props)=> {
    if(props.currentTeam && props.currentTeam.length === 1){
        let team = props.currentTeam[0]
        inputData.name = team.name;
        inputData.descriptions = team.attributes.descriptions;
        inputData.members = team.attributes.members;
        function close(){
            props.onCloseModel('viewTeam');
            inputData = {}
        }
        function updateTeam(){
            if(inputData.name){
                let nameRegExp = /^[a-z0-9 &]+$/i;
                if(nameRegExp.test(inputData.name)){
                    props.updateTeam(team.id, inputData)
                    inputData = {}
                } else{
                    showNotification('error', 'Invalid team name')
                }
            } else {
                showNotification('error', 'Name is mandatory')
            }
        }

        return <Modal
            visible={props.visible}
            footer={null}
            key={team.id}
            closable={false}
        >
            <Row type='flex' justify='space-between'>
                <span style={{color: '#44566C',  fontSize: '30px', fontWeight: 'bold'}}>Edit Team</span>
                <Button onClick={()=>close()} shape="circle"  icon="close" style={{color: '#44566C', backgroundColor:'#F8FAFB', fontSize: '17px'}}/>
            </Row>

            <Col style={{marginTop: '20px'}}>
                <span style={{color: '#44566C'}}>Team Name</span>
                <Input
                    defaultValue={team.name}
                    onChange={(e)=>inputData.name = e.target.value}
                    className='custom-input mt-5'
                    size='large'
                    maxLength={35}
                />
            </Col>
            <Col style={{marginTop: '20px'}}>
                <span style={{color: '#44566C'}}>Descriptions</span>
                <TextArea
                    defaultValue={team.attributes.descriptions}
                    onChange={(e)=>inputData.descriptions = e.target.value}
                    className='custom-input mt-5'
                    size='large'
                    maxLength={100}
                />
            </Col>
            <Col style={{marginTop: '20px'}}>
                <span style={{color: '#44566C'}}>Add Members</span>
                <Select
                    getPopupContainer={(e)=>e.parentNode}
                    className='custom-select fw mt-5'
                    mode="multiple"
                    defaultValue={team.attributes.members.map(e=>{return e._id})}
                    placeholder="Select members..."
                    onChange={(e)=>inputData.members = e}
                    size='large'
                    showSearch
                    optionFilterProp = "children"
                    filterOption     = {(input, option) => option.props.children[1].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {/* {
                        props.members && props.members.length > 0 && props.members.map(val=>{
                            return <Option key={val.id}>{val.attributes.name||'Invited'}</Option>
                        })
                    } */}
                    {
                        props.members && props.members.length > 0 && props.members.map((user, index)=>{
                            if(user.attributes.active){
                                return <Select.Option key={user.id} value={user.id}>
                                    {
                                        user.attributes.picture ?
                                        <Avatar size={'small'} src={user.attributes.picture}/> :
                                        <Avatar size="small" style={{backgroundColor: getAvatarColor(user.id)}}>
                                            {getNameInitials(user.attributes.name ? user.attributes.name : user.attributes.email)}
                                        </Avatar>
                                    }
                                    <span className='ml-10'>{user.attributes.name || user.attributes.email}</span>
                                </Select.Option>
                            }
                        })
                    }
                </Select>
            </Col>
            <Row style={{marginTop: '20px'}} type='flex' justify={'end'}>
                <Button onClick={()=>close()} style={{marginRight: '10px'}}>Cancel</Button>
                <Button onClick={()=>updateTeam()} type="primary">Update</Button>
            </Row>
        </Modal>
    }
    return null;

}
export default viewTeam