import React from 'react';
import {
    Row,
    Button,
    Input,
    Col,
    Modal
} from 'antd';
import {showNotification} from "../../utils/commonFunctions";
import FormFields from '../form/formFields'
import {connect} from "react-redux";

const {TextArea} = Input;
let inputData = {};
let count = 0;

class addRecord extends React.Component {
    // setup state
    state = {
        loading: false
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if(!this.props.record.loading && this.state.loading){
    //      console.log('11111')
    //         this.setState({loading: false})
    //         this.props.onCloseModel(false)
    //     }
    // }

    createRecord = () => {
        inputData.name = "demo";
        this.props.create(inputData);
        inputData = {};
        count++;
    };

    close = () => {
        inputData = {
            // name: null,
            // description: null,
            members: []
        };
        count = count - 1;
        this.props.onCloseModel()
    };

    setFieldValue = (info) => {
        inputData.fields = {...info.fields, ...inputData.fields};
        console.log(info, inputData.fields)

    }

    render() {
        // let {loading} = this.state
        return <Modal
            visible={this.props.visible}
            footer={null}
            key={count}
            width={'60%'}
            bodyStyle={{minHeight: '500px'}}
            closable={false}
        >
            <Row type='flex' justify='space-between' key={count}>
                <span className={'text-font-fp'}>Add Record</span>
                <Button onClick={() => this.close()} shape="circle" icon="close"
                        style={{color: '#44566C', backgroundColor: '#F8FAFB', fontSize: '16px', border: 0}}/>
            </Row>
            {/*<Col className='mt-20 ml-10'>*/}
            {/*    <label>Record name <sup style={{fontSize:12, color: 'red', top: 0}}> *</sup></label>*/}
            {/*    <Input*/}
            {/*        onChange={(e)=>inputData.name = e.target.value}*/}
            {/*        className='custom-input mt-5'*/}
            {/*        size = 'large'*/}

            {/*    />*/}
            {/*</Col>*/}
            {/*<Col className='mt-20 ml-10'>*/}
            {/*    <span style={{color: '#44566C'}}>Descriptions</span>*/}
            {/*    <TextArea*/}
            {/*        className='custom-input'*/}
            {/*        onChange={(e)=>inputData.description = e.target.value}*/}
            {/*        style={{backgroundColor: '#F8FAFB', marginTop: '5px'}}*/}
            {/*    />*/}
            {/*</Col>*/}
            <FormFields
                sections={this.props.dataSet.attributes.sections}
                fields={this.props.objectFields}
                activeDataset={this.props.dataSet}
                updateRecordFields={this.setFieldValue}
            />
            <Row style={{position: 'absolute', bottom: 0, right: 0, height: 50, width: '100%', background: '#F8FAFB'}}>
                <Row style={{position: 'absolute', bottom: 10, right: 10}}>
                    <Button onClick={() => this.close()} className='mr-10'>Cancel</Button>
                    <Button loading={this.state.loading} onClick={this.createRecord} type="primary">Add</Button>
                </Row>
            </Row>
        </Modal>
    }
}

const mapStateToProps = (state) => {
    return {
        users: state.users,
        teams: state.teams,
        record: state.record,
        dataset: state.dataset
    }
}
export default connect(mapStateToProps)(addRecord)
