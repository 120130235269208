import React, { Component } from 'react'
import {DatePicker} from 'antd'
import moment from 'moment'

export default class SingleSelectDate extends Component {

    state = {
        selectedDate : (this.props.activeTask && this.props.activeTask.fields && this.props.activeTask.fields[this.props.field.id]) ? this.props.activeTask.fields[this.props.field.id].value : null
    }

    componentDidUpdate(prevProps){
        let {activeTask, field} = this.props
        if(activeTask && activeTask.fields && activeTask.fields[field.id]){
            if(activeTask.fields[field.id].value !== prevProps.activeTask.fields[field.id].value){
                this.setState({selectedDate: activeTask.fields[field.id].value})
            }
        }
    }

    
    handleDate = (date, fieldId) => {
        const val = date ? date.valueOf() : null
        this.setState({selectedDate: val })
        this.props.addValue(fieldId, val)
    }


    render() {
        let {field} = this.props
        return (
            <DatePicker
                size        = 'large'
                placeholder = {field.alias_help_text ? field.alias_help_text : field.helpText}
                className   = 'fw custom-input'
                format      = {'DD/MM/YYYY'}
                onChange    = {e=>this.handleDate(e,field.id)}
                value       = {this.state.selectedDate ? moment(moment(this.state.selectedDate).format('DD/MM/YYYY'),'DD/MM/YYYY') : null}
            />
        )
    }
}
