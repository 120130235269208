import TYPES from './ProjectActionTypes';

const initialState = {
  active: undefined,
  projects: undefined,
  templates: undefined,
  createdTask: undefined,
  createdTaskDummyId: undefined,
  navigationProjects: undefined,
  updatedProject: undefined
}

const ProjectReducer = (state=initialState, action) => {
  switch(action.type){
    case TYPES.FETCH_PROJECTS_SUCCESS:
      return Object.assign({}, state, {
        projects: {...state.projects, ...action.projects}
      });
    case TYPES.FETCH_PROJECT_SUCCESS:
      return Object.assign({}, state, {
        active: action.project
      });
    case TYPES.FETCH_PROJECT_TEMPLATES_SUCCESS:
      return Object.assign({}, state, {
        templates: action.payload.data
      });
    case TYPES.FETCH_NAVIGATION_PROJECTS_SUCCESS:
      return Object.assign({}, state, {
        navigationProjects: action.projects
      });
    case TYPES.FETCH_NAVIGATION_PROJECTS_PUSH_SUCCESS:
      return Object.assign({}, state, {
        navigationProjects: [action.project, ...state.navigationProjects]
      });
    case TYPES.CREATE_NEW_PROJECT_SUCCESS:
      return Object.assign({}, state, {
        active: action.payload.data,
        projects: [action.payload.data, ...state.projects]
      })
    case TYPES.UPDATE_PROJECT_TEMP:
      return Object.assign({}, state, {
        projects: Object.assign({}, state.projects, {[action.project._id]: action.project})
      });
    case TYPES.CREATE_PROJECT_TASK_SUCCESS:
      return Object.assign({}, state, {
        createdTask: action.task,
        createdTaskDummyId: action.dummyId
      });
    case TYPES.UPDATE_PROJECT:
      return Object.assign({}, state, {
        updatedProject: undefined
      });
    case TYPES.UPDATE_PROJECT_SUCCESS:
      return Object.assign({}, state, {
        updatedProject: action.id
      });
    case TYPES.DELETE_PROJECT:
      return Object.assign({}, state, {
        projectDeleted: undefined
      })
    case TYPES.DELETE_PROJECT_SUCCESS:
      return Object.assign({}, state, {
        projectDeleted: action.id
      })
    case TYPES.DELETE_PROJECT:
    case TYPES.REMOVE_PROJECT:
      const { id } = action;
      return Object.assign({}, state, {
        projects: {...state.projects, ...{[id]: undefined}},
        navigationProjects: state.navigationProjects.filter(project => project !== id)
      });
    case TYPES.FETCH_MARKETPLACE_PROJECTS_SUCCESS:
      return Object.assign({}, state, {
        marketplaceProjects: action.apps
      })
    default:
      return state;
  }
}

export default ProjectReducer;
