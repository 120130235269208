import React from 'react';
import {Row, Popover, Avatar, Button, Input, Select, Col, Modal} from 'antd';
// import CreateNewModal from '../../utils/createNewModal'
import {showNotification, getNameInitials, getAvatarColor} from '../../utils/commonFunctions'
// import {isvalidName} from "../../utils/validationFunctions";

const {TextArea} = Input;
const {Option} = Select;
let inputData = {};
let count = 0;

class addTeam extends React.Component {
    state = {
        loading: false
    }

    createTeam = () => {
        if (inputData.name) {
            let nameRegExp = /^[a-z0-9 &]+$/i;
            if (nameRegExp.test(inputData.name)) {
                this.setState({loading: true})
                this.props.createTeam(inputData)
                inputData = {
                    name: null,
                    descriptions: null,
                    members: []
                };
                count = count + 1;
                this.setState({loading: false})
            } else {
                showNotification('error', 'Special character not allowed')
            }
        } else {
            showNotification('error', 'Name is mandatory')
        }
    }

    close = () => {
        inputData = {
            name: null,
            descriptions: null,
            members: []
        };
        count = count - 1;
        this.props.onCloseModel('addTeam')
    };

    render() {
        return <Modal
            visible={this.props.visible}
            footer={null}
            key={count}
            closable={false}
        >
            <Row type='flex' justify='space-between' key={count}>
                <span style={{color: '#44566C', fontSize: '30px', fontWeight: 'bold'}}>Create New Team</span>
                <Button onClick={() => this.close()} shape="circle" icon="close"
                        style={{color: '#44566C', backgroundColor: '#F8FAFB', fontSize: '17px'}}/>
            </Row>
            <Col style={{marginTop: '20px'}}>
                <span style={{color: '#44566C'}}>Team Name<sup
                    style={{fontSize: 12, color: 'red', top: 0}}> *</sup></span>
                <Input
                    onChange={(e) => inputData.name = e.target.value}
                    placeholder='Enter team name'
                    className='custom-input mt-5'
                    size='large'
                    maxLength={35}
                />
            </Col>
            <Col style={{marginTop: '20px'}}>
                <span style={{color: '#44566C'}}>Descriptions</span>
                <TextArea
                    onChange={(e) => inputData.descriptions = e.target.value}
                    className='custom-input mt-5'
                    size='large'
                    placeholder='Enter description'
                    maxLength={100}
                />
            </Col>
            <Col style={{marginTop: '20px'}}>
                <span style={{color: '#44566C'}}>Add Members</span>
                <Select
                    getPopupContainer={(e) => e.parentNode}
                    className='custom-select fw mt-5'
                    size='large'
                    mode="multiple"
                    placeholder="Select members..."
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.props.children[1].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={(e) => inputData.members = e}
                >
                    {/* {
                        this.props.members && this.props.members.length > 0 && this.props.members.map(val=>{
                            return <Option key={val.id}>{val.attributes.name||'Invited'}</Option>
                        })
                    } */}
                    {
                        this.props.members && this.props.members.length > 0 && this.props.members.map((user, index) => {
                            if (user.attributes.active) {
                                return <Select.Option key={user.id} value={user.id}>
                                    {
                                        user.attributes.picture ? <Avatar size={"small"} src={user.attributes.picture}/>
                                            :
                                            <Avatar size="small" style={{backgroundColor: getAvatarColor(user.id)}}>
                                                {getNameInitials(user.attributes.name ? user.attributes.name : user.attributes.email)}
                                            </Avatar>
                                    }
                                    <span className='ml-10'>{user.attributes.name || user.attributes.email}</span>
                                </Select.Option>
                            }
                        })
                    }
                </Select>
            </Col>
            <Row style={{marginTop: '20px'}} type='flex' justify={'end'}>
                <Button onClick={() => this.close()} style={{marginRight: '10px'}}>Cancel</Button>
                <Button loading={this.state.loading} onClick={() => this.createTeam()} type="primary">Create</Button>
            </Row>
        </Modal>
    }
}

export default addTeam;
