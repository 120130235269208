import React from 'react';
import {Row, Button, Input, Select, Col, Drawer, Avatar} from 'antd';
import {isvalidEmail, isvalidPhone} from "../../utils/validationFunctions";
import {showNotification} from "../../utils/commonFunctions";

let {Option} = Select;
let inputData = {};

class ViewPeople extends React.Component {
    constructor(props){
        super(props);
        this.state= {
            editable: false
        }
    }

    onUpdatePress = () => {
        let id = this.props.item.id;
        if(inputData.designation !== undefined){
            if(inputData.designation.split(' ').join('').length > 0){
               this.props.onUpdate(id, inputData);
               this.setState({editable: false});
               this.props.onCloseModel('viewPeople')
           } else {
               showNotification('error', `Invalid designation.`)
           }
        }
        if(inputData.designation === undefined) {
            this.props.onUpdate(id, inputData);
            this.setState({editable: false});
            this.props.onCloseModel('viewPeople')
        }
    }
    onClosePress = () => {
        this.setState({editable: false});
        this.props.onCloseModel('viewPeople');
    }

    onEditPress = () =>{
        this.setState({editable: true});
    }

    onDeletePress = () => {
        let id = this.props.item.id
        this.props.onDelete(id);
        this.props.onCloseModel('viewPeople');
    }

    render() {
        if (this.props.item) {
            let {name, email, role_id, phone, designation, picture} = this.props.item;
            let optionContent;

            if (this.state.editable) {
                optionContent =
                    <Col>
                        <Row style={{marginTop: '20px'}} type='flex' justify='center'>
                            <Button shape="circle" icon="check" onClick={this.onUpdatePress}
                                    style={{marginRight: 10, color: '#067F4C'}}/>
                            <Button shape="circle" icon="close" style={{marginLeft: 10, color: '#FF3D57'}}
                                    onClick={this.onClosePress}/>
                        </Row>
                        <hr style={{marginTop: '20px'}}/>
                        {/*<Col style={{marginTop: '20px'}}>*/}
                        {/*    <span style={{color: '#44566C'}}>NAME</span>*/}
                        {/*    <Input*/}
                        {/*        onChange={(e) => inputData.name = e.target.value}*/}
                        {/*        defaultValue={name}*/}
                        {/*        style={{backgroundColor: '#F8FAFB', marginTop: '5px'}}*/}
                        {/*    />*/}
                        {/*</Col>*/}
                        <Col style={{marginTop: '15px'}}>
                            <span style={{color: '#44566C'}}>DESIGNATION<sup style={{fontSize:12, color: 'red', top: 0}}> *</sup></span>
                            <Input
                                maxLength={35}
                                onChange={(e) => inputData.designation = e.target.value}
                                defaultValue={designation}
                                style={{backgroundColor: '#F8FAFB', marginTop: '5px'}}
                            />
                        </Col>
                        <Col style={{marginTop: '15px'}}>
                            <span style={{color: '#44566C'}}>ROLE</span><br/>
                            <Select
                                getPopupContainer={(e)=>e.parentNode}
                                defaultValue={role_id}
                                style={{width: '100%', backgroundColor: '#F8FAFB', marginTop: '5px'}}
                                onChange={(e) => inputData.role_id = e}
                                placeholder="Eg: Admin, Manager etc...">
                                <Option value="admin">Administrator</Option>
                                <Option value="member">Member</Option>
                            </Select>
                        </Col>
                        <Col style={{marginTop: '15px'}}>
                            <span style={{color: '#44566C'}}>EMAIL</span>
                            <Input
                                defaultValue={email}
                                disabled={true}
                                // onChange={(e) => inputData.email = e.target.value}
                                style={{backgroundColor: '#F8FAFB', marginTop: '5px'}}
                            />
                        </Col>
                        {/*<Col style={{marginTop: '15px'}}>*/}
                        {/*    <span style={{color: '#44566C'}}>PHONE</span>*/}
                        {/*    <Input*/}
                        {/*        onChange={(e) => inputData.phone = e.target.value}*/}
                        {/*        defaultValue={phone}*/}
                        {/*        style={{backgroundColor: '#F8FAFB', marginTop: '5px'}}*/}
                        {/*    />*/}
                        {/*</Col>*/}
                    </Col>
            } else {
                optionContent =
                    <Col>
                        <Col type='flex' justify='center' align='center' style={{marginTop: '10px'}}>
                            <span style={{color: '#44566C', fontSize: '20px'}}>{name || 'No Name'}</span><br/>
                            <span style={{color: '#8697A8'}}>{designation ||'No Designation'}</span>
                        </Col>
                        <Row type='flex' justify='space-between' style={{marginTop: '10px'}}>
                            <Button shape="circle" onClick={this.onEditPress} icon="edit"
                                    style={{backgroundColor: '#E6EAED', color: '#0067CC'}}/>
                            {/*<Button shape="circle" icon="stop" style={{backgroundColor: '#E6EAED', color: '#0067CC'}}/>*/}
                            {/*<Button shape="circle" onClick={this.onDeletePress} icon="delete"*/}
                            {/*        style={{backgroundColor: '#E6EAED', color: '#0067CC'}}/>*/}
                            {/*<Button shape="circle" icon="setting" style={{backgroundColor: '#E6EAED', color: '#0067CC'}}/>*/}
                        </Row>
                        <hr style={{marginTop: '20px'}}/>
                        <Col style={{marginTop: '20px'}}>
                            <span style={{color: '#8697A8'}}>ROLE</span><br/>
                            <span style={{color: '#2E384D', marginTop: '10px', textTransform: 'capitalize'}}>{role_id|| 'No role'}</span>
                        </Col>
                        <Col style={{marginTop: '20px'}}>
                            <span style={{color: '#8697A8'}}>EMAIL</span><br/>
                            <span style={{color: '#2E384D', marginTop: '10px'}}>{email}</span>
                        </Col>
                        {/*<Col style={{marginTop: '20px'}}>*/}
                        {/*    <span style={{color: '#8697A8'}}>PHONE</span><br/>*/}
                        {/*    <span style={{color: '#2E384D', marginTop: '10px'}}>{phone || 'No phone'}</span>*/}
                        {/*</Col>*/}
                    </Col>
            }
            return (
                <Drawer
                    placement="right"
                    closable={false}
                    width={350}
                    onClose={this.onClosePress}
                    visible={this.props.visible}
                >
                    <Row type='flex' justify='center'>
                        {picture ?
                            <Avatar size="large" src={picture} style={{
                                width: '150px',
                                height: '150px',
                            }}/>
                            :
                            <Button shape="circle" icon="user" style={{
                                fontSize: '80px',
                                backgroundColor: '#F8FAFB',
                                width: '150px',
                                height: '150px',
                                marginRight: 10,
                                color: '#067F4C'
                            }}/>
                        }

                    </Row>
                    {optionContent}
                </Drawer>
            );
        } else {
            return null
        }
    }
}
export default ViewPeople;