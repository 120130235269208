const ACTIONS = {

    FETCH_REQUEST       : 'FETCH_MASTER_REQUEST',
    FETCH_SUCCESS       : 'FETCH_MASTER_SUCCESS',
    FETCH_FAIL          : 'FETCH_MASTER_FAIL',

    FETCH_ONE_REQUEST   : 'FETCH_ONE_MASTER_REQUEST',
    FETCH_ONE_SUCCESS   : 'FETCH_ONE_MASTER_SUCCESS',
    FETCH_ONE_FAIL      : 'FETCH_ONE_MASTER_FAIL',

    FETCH_MORE_REQUEST  : 'FETCH_MORE_MASTER_REQUEST',
    FETCH_MORE_SUCCESS  : 'FETCH_MORE_MASTER_SUCCESS',
    FETCH_MORE_FAIL     : 'FETCH_MORE_MASTER_FAIL',

    CREATE_REQUEST      : 'CREATE_MASTER_REQUEST',
    CREATE_SUCCESS      : 'CREATE_MASTER_SUCCESS',
    CREATE_FAIL         : 'CREATE_MASTER_FAIL',

    UPDATE_REQUEST      : 'UPDATE_MASTER_REQUEST',
    UPDATE_SUCCESS      : 'UPDATE_MASTER_SUCCESS',
    UPDATE_FAIL         : 'UPDATE_MASTER_FAIL',

    DELETE_REQUEST      : 'DELETE_MASTER_REQUEST',
    DELETE_SUCCESS      : 'DELETE_MASTER_SUCCESS',
    DELETE_FAIL         : 'DELETE_MASTER_FAIL',
}

export default ACTIONS;