import {socket} from "../../config/SocketInit";
import USER_ACTIONS from '../../people/duck/UserActionTypes';
import CURRENT_USER_ACTIONS from '../../profile/duck/ProfileActionTypes';
import TEAMS_ACTIONS from '../../teams/duck/TeamsActionTypes';
import RECORD_ACTIONS from '../../record/duck/RecordActionTypes';
import {store} from '../../config/Store';
import jwtDecode from 'jwt-decode';
const {dispatch} = store;

function userSocket() {
    socket.on('users', function (user) {
        console.log('USER SOCKET', user);
        const globalState = store.getState();
        let teams, teamNames;
        switch (user.meta.action) {
            case 'invited':
                teamNames = user.data.attributes.teams;
                teams = globalState.teams.data && globalState.teams.data.data;
                if (teams && teams.length > 0) {
                    teams.forEach(obj => {
                        teamNames.map(team => {
                            console.log(team._id , obj.id)
                            if (team._id === obj.id) {
                                let data = {
                                    ...user.data.attributes,
                                    _id: user.data.id,
                                    teams: team.name
                                };
                                obj.attributes.members.unshift(data)
                            }
                        })
                    })
                }
                dispatch({type: USER_ACTIONS.CREATE_SUCCESS, payload: user})
                break;
            case 'create':
                dispatch({type: USER_ACTIONS.CREATE_SUCCESS, payload: user})
                break;
            case 'update':
                if (user.data.id === jwtDecode(localStorage.getItem('jwt')).id) {
                    dispatch({type: CURRENT_USER_ACTIONS.UPDATE_SUCCESS, payload: user})
                } else {
                    dispatch({type: USER_ACTIONS.UPDATE_SUCCESS, payload: user})
                }
                break;
            case 'delete':
                dispatch({type: USER_ACTIONS.DELETE_SUCCESS, payload: user})
                break;
            default: break;
        }
    })
}

function teamSocket() {
    socket.on('teams', function (team) {
        console.log('TEAM SOCKET', team);
        const globalState = store.getState();
        let members, users;
        switch (team.meta.action) {
            case 'create':
                members = team.data.attributes.members;
                users = globalState.users.data && globalState.users.data.data;
                if (members.length > 0) {
                    members.map(obj => {
                        users && users.forEach(user => {
                            if (user.id === obj._id) {
                                user.attributes.teams.unshift({_id: team.data.id, name: team.data.name})
                            }
                        })
                    })
                }
                dispatch({type: TEAMS_ACTIONS.CREATE_SUCCESS, payload: team})
                break;
            case 'update':
                members = team.data.attributes.members;
                users = globalState.users.data && globalState.users.data.data;
                if (members.length > 0) {
                    members.map(obj => {
                        users && users.forEach(user => {
                            if (user.id === obj._id) {
                                console.log(user)
                                if (user.attributes.teams.length > 0) {
                                    if (user.attributes.teams.find(obj => obj._id !== team.data.id)) {
                                        user.attributes.teams.unshift({_id: team.data.id, name: team.data.name})
                                    }
                                } else {
                                    user.attributes.teams.unshift({_id: team.data.id, name: team.data.name})
                                }
                            }
                        })
                    })
                }
                dispatch({type: TEAMS_ACTIONS.UPDATE_SUCCESS, payload: team})
                break;
            case 'delete':
                users = globalState.users.data && globalState.users.data.data;
                users && users.forEach(user => {
                    if (user.attributes.teams.length > 0) {
                        if (user.attributes.teams.find(obj => obj._id === team.data.id)) {
                            console.log(user.attributes.teams.filter(obj => obj._id !== team.data.id))
                            user.attributes.teams = user.attributes.teams.filter(obj => obj._id !== team.data.id)
                        }
                    }
                });
                dispatch({type: TEAMS_ACTIONS.DELETE_SUCCESS, payload: team})
                break;
        }
    })
}

function recordSocket() {
    socket.on('record', function (record) {
        console.log('RECORD SOCKET', record);
        switch (record.meta.action) {
            case 'create':
                dispatch({type: RECORD_ACTIONS.CREATE_SUCCESS, payload: record})
                break;
            case 'update':
                dispatch({type: RECORD_ACTIONS.UPDATE_SUCCESS, payload: record})
                break;
            case 'delete':
                dispatch({type: RECORD_ACTIONS.DELETE_SUCCESS, payload: record})
                break;
        }
    })
}

function datasetSocket() {
    socket.on('datasets', function (dataset) {
        console.log('DATASET SOCKET', dataset);
        switch (dataset.meta.action) {
            case 'create':
                // dispatch({type: DATASET_ACTIONS.CREATE_SUCCESS, payload: dataset})
                break;
            case 'update':
                // dispatch({type: DATASET_ACTIONS.UPDATE_SUCCESS, payload: dataset})
                break;
            case 'delete':
                // dispatch({type: DATASET_ACTIONS.DELETE_SUCCESS, payload: dataset})
                break;
        }
    })
}

export {
    userSocket,
    teamSocket,
    recordSocket,
    datasetSocket
}