import React from 'react';
import { Icon, Row, Modal, Button, Form, Input, Select, Upload, message } from 'antd';
import { Container, Draggable } from "react-smooth-dnd";
import appUrl from '../../../constants/AppUrl';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

import './styles.css';

const { Dragger } = Upload;

const formLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
  labelAlign: "left"
};

class PublishProjectToMarketplaceModal extends React.Component {

  state = {
    marketplaceApp: this.props.marketplaceApp,
    project: this.props.project,
    marketPlaceSavingDraft: false,
    marketplacePublish: false,
    showPreview: false,
  }

  constructor(props) {
    super(props);
    const marketplaceApp = Object.assign({}, this.props.marketplaceApp);
    marketplaceApp.meta = marketplaceApp.meta || {};
    marketplaceApp.meta.details_sections = marketplaceApp.meta.details_sections || [];
    this.state.marketplaceApp = marketplaceApp;
  }

  componentDidUpdate(prevProps) {
    if (this.props.project !== prevProps.project) {
      this.setState({
        project: this.props.project
      })
    }
    if (this.props.marketplaceApp !== prevProps.marketplaceApp) {
      const marketplaceApp = Object.assign({}, this.props.marketplaceApp);
      marketplaceApp.meta = marketplaceApp.meta || {};
      marketplaceApp.meta.details_sections = marketplaceApp.meta.details_sections || [];
      this.setState({
        marketplaceApp: marketplaceApp
      })
    }
    if (this.props.updatedApp !== prevProps.updatedApp && this.props.updatedApp === this.state.marketplaceApp._id) {
      this.state.marketPlaceSavingDraft && message.success('App saved as draft!');
      this.state.marketplacePublish && message.success('App published');
      this.state.marketplacePublish && this.props.history.push('/app/projects?panel=marketplace');
      this.setState({
        marketPlaceSavingDraft: false,
        marketplacePublish: false
      })
    }
    if (this.props.updatedAppFail !== prevProps.updatedAppFail && this.props.updatedAppFail === this.state.marketplaceApp._id) {
      this.state.marketPlaceSavingDraft && message.success('Error saving draft!');
      this.state.marketplacePublish && message.success('Error publishing app!');
      this.setState({
        marketPlaceSavingDraft: false,
        marketplacePublish: false
      })
    }
  }

  setCategory = (value) => {
    const project = Object.assign({}, this.state.project);
    project.category = value;
    this.setState({
      project
    })
  }

  addNewDetailsSection = () => {
    const marketplaceApp = Object.assign({}, this.state.marketplaceApp);
    marketplaceApp.meta.details_sections = [
      ...this.state.marketplaceApp.meta.details_sections,
      {
        title: "",
        description: "",
        images: []
      }
    ];
    this.setState({
      marketplaceApp,
    })
  }

  removeSection = (index) => {
    const marketplaceApp = Object.assign({}, this.state.marketplaceApp);
    marketplaceApp.meta.details_sections = marketplaceApp.meta.details_sections.slice(0, index).concat(marketplaceApp.meta.details_sections.slice(index+1));
    this.setState({
      marketplaceApp
    })
  }

  setSectionTitle = (title, index) => {
    const marketplaceApp = Object.assign({}, this.state.marketplaceApp);
    marketplaceApp.meta.details_sections[index].title = title;
    this.setState({
      marketplaceApp
    });
  }

  setSectionDescription = (description, index) => {
    const marketplaceApp = Object.assign({}, this.state.marketplaceApp);
    marketplaceApp.meta.details_sections[index].description = description;
    this.setState({
      marketplaceApp
    });
  }

  removeFile = (fileData, index) => {
    const marketplaceApp = Object.assign({}, this.state.marketplaceApp);
    let indexToDelete = -1;
    marketplaceApp.meta.details_sections[index].images.map((image, index) => {
      if (image._id === fileData.uid || image.uid === fileData.uid) {
        indexToDelete = index;
      }
    })
    marketplaceApp.meta.details_sections[index].images = marketplaceApp.meta.details_sections[index].images.slice(0, indexToDelete)
      .concat(marketplaceApp.meta.details_sections[index].images.slice(indexToDelete+1));
    this.setState({
      marketplaceApp
    })
  }

  uploadFile = (fileData, index) => {
    const { file, onProgress, onError, onSuccess } = fileData;
    const { project } = this.props;
    const action = appUrl.ATTACHMENTS;
    if (file.size > 10000000) {
      message.error('File size larger than 10Mb');
      return onError('File size larger than 10Mb');
    }
    const formData = new FormData();
    formData.append('file', file);
    formData.append('onModel', 'App');
    formData.append('model_id', project.app_id);
    axios
    .post(action, formData, {
      onUploadProgress: ({total, loaded}) => {
        const progress = Math.round(loaded / total * 100);
        onProgress({percent: progress})
      },
    })
    .then((response) => {
      if (response.data.status && response.data.status !== 200) {
        message.error(response.data.notAccepted[0].reason);
        onError(response.data.notAccepted[0].reason);
      } else {
        const marketplaceApp = Object.assign({}, this.state.marketplaceApp);
        marketplaceApp.meta.details_sections[index].images = marketplaceApp.meta.details_sections[index].images.map((image) => {
          if (image.uid === file.uid) {
            return response.data.data[0];
          }
          return image;
        });
        this.setState({
          marketplaceApp
        })
        onSuccess(response)
      }
    })
    .catch((error) => onError(error));
  }

  handleChange = ({ fileList }, index) => {
    const marketplaceApp = Object.assign({}, this.state.marketplaceApp);
    marketplaceApp.meta.details_sections[index].images = fileList;
    this.setState({
      marketplaceApp
    });
  }

  renderDetailsSection = (section, index) => {
    return (
      <div className="add-meta-section" style={{cursor: "auto"}} key={index}>
        <div className="add-meta-section-heading">
          <Icon className="drag-section-handle" type="menu" style={{marginRight: 8, cursor: "pointer"}}/>
          <span>Add more details</span>
          <Icon
            type="close-circle"
            style={{marginLeft: 8, cursor: "pointer", color: "#ff4d4f"}}
            onClick={() => this.removeSection(index)}
          />
        </div>
        <Form {...formLayout} style={{marginTop: 20}}>
          <Form.Item
            label={<span className='text-color'>Title</span>}
            colon={false}
          >
            <Input
              placeholder="Mars Life"
              size='large'
              value={section.title}
              onChange={(e) => this.setSectionTitle(e.target.value, index)}
            />
          </Form.Item>
          <Form.Item
            label={<span className='text-color'>Description</span>}
            colon={false}
          >
            <Input.TextArea
              autosize={{minRows: 4, maxRows: 10}}
              value={section.description}
              placeholder="Taking mankind forward"
              onChange={(e) => this.setSectionDescription(e.target.value, index)}
            />
          </Form.Item>
          <Form.Item
            label={<span className='text-color'>Attachments</span>}
            colon={false}
          >
            <Dragger
              accept=".png,.jpg,.jpeg"
              listType="picture-card"
              customRequest={(fileData) => this.uploadFile(fileData, index)}
              onChange={(data) => this.handleChange(data, index)}
              onRemove={(fileData) => this.removeFile(fileData, index)}
              fileList={section.images.map(image => { return image._id ? {...image, uid: image._id, url: image.thumb_location} : image})}
              multiple={true}
              className="section-image-upload"
            >
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
            </Dragger>
          </Form.Item>
        </Form>
      </div>
    );
  }

  hangleSectionDrop = ({ removedIndex, addedIndex }) => {
    const marketplaceApp = Object.assign({}, this.state.marketplaceApp);
    const removedSection = marketplaceApp.meta.details_sections[removedIndex];
    marketplaceApp.meta.details_sections = marketplaceApp.meta.details_sections.slice(0, removedIndex)
      .concat(marketplaceApp.meta.details_sections.slice(removedIndex+1));
    marketplaceApp.meta.details_sections.splice(addedIndex, 0, removedSection);
    this.setState({
      marketplaceApp
    })
  }

  saveMarketplaceAppDraft = () => {
    const { marketplaceApp } = this.state;
    this.props.updateMarketplaceApp({
      data: marketplaceApp
    });
    this.setState({
      marketPlaceSavingDraft: true
    })
  }

  publishMarketplaceApp = () => {
    const { marketplaceApp } = this.state;
    const { project } = this.props;
    marketplaceApp.draft = false;
    marketplaceApp.data = project;
    this.props.updateMarketplaceApp({
      data: marketplaceApp
    });
    this.setState({
      marketplacePublish: true
    })
  }

  showPreview = () => {
    this.setState({
      showPreview: true
    })
  }

  hidePreview = () => {
    this.setState({
      showPreview: false
    })
  }

  renderPublishForm() {
    const { visible, onCancel, categories } = this.props;
    const { showPreview, project, marketplaceApp, marketplacePublish, marketPlaceSavingDraft } = this.state;
    return (
      <>
        <Form {...formLayout}>
          <Form.Item
            label={<span className='text-color'>Name</span>}
            colon={false}
          >
            <Input
              placeholder="Mars Life"
              size='large'
              className='custom-input'
              value={project.name}
              maxLength={50}
              disabled
            />
          </Form.Item>
          <Form.Item
            label={<span className='text-color'>Description</span>}
            colon={false}
          >
            <Input.TextArea
              autosize={{minRows: 4}}
              value={project.description}
              className='custom-input'
              disabled
            />
          </Form.Item>
          <Form.Item label={<span className='text-color'>Category</span>} colon={false}>
            <Select
              size = 'large'
              getPopupContainer={(e)=>e.parentNode}
              mode="multiple"
              className="custom-select"
              placeholder="Select project category"
              onChange={this.setCategory}
                value={project.category.map(category => category._id)}
              notFoundContent="No category found"
              filterOption={(inputValue, option) => {
                return option.props.category &&
                  option.props.category.name &&
                  option.props.category.name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
              }}
            >
              {categories && categories.map(category => <Select.Option
                category={category}
                value={category._id}
                key={category._id}
              >
                {category.name}
              </Select.Option>)}
            </Select>
          </Form.Item>
        </Form>
        <Container
          orientation="vertical"
          onDrop={this.hangleSectionDrop}
          dragHandleSelector=".drag-section-handle"
        >
          {marketplaceApp.meta.details_sections.map((section, index) => (<Draggable
            key={index}
          >
            {this.renderDetailsSection(section, index)}
          </Draggable>))}
        </Container>
        <div className="add-meta-section" onClick={this.addNewDetailsSection}>
          <div className="add-meta-section-heading">+ Add more details</div>
        </div>
      </>
    );
  }

  renderReview = () => {
    const { marketplaceApp } = this.state;
    return (
      <Row style={{margin: "auto", width: "90%"}}>
        <Row type="flex">
          <div style={{flexGrow: 1}}>
            <div style={{fontSize: 30}}>
              {marketplaceApp.data.name}
            </div>
            <div style={{fontSize: 16}}>
              {marketplaceApp.data.description}
            </div>
          </div>
          <Row type="flex" justify="center" align="middle">
            <Button type="primary">+ Install</Button>
          </Row>
        </Row>
        {marketplaceApp.meta.details_sections.map(section =>
          <div style={{marginTop: 50}}>
            <div style={{fontSize: 24, fontWeight: 600, color: "#44566C"}}>{section.title}</div>
            <div style={{color: "#44566C", fontSize: 16}}>{section.description}</div>
            <div>
              {section.images.map(image =>
                <div style={{margin: "20px 0px"}}>
                  <img style={{width: "100%"}} src={image.location}/>
                </div>
              )}
            </div>
          </div>
        )}
      </Row>
    );
  }

  render() {
    const { visible, onCancel, categories } = this.props;
    const { showPreview, project, marketplaceApp, marketplacePublish, marketPlaceSavingDraft } = this.state;
    return (
      <Modal
        centered={true}
        className="custom-modal"
        width="80%"
        title={!showPreview ?
          'Publish to marketplace' :
          <div style={{textAlign: "center"}}>
            <Icon style={{float: "left"}} type="left-circle" onClick={this.hidePreview}/>Preview
          </div>}
        visible={visible}
        onOk={this.handleOk}
        onCancel={onCancel}
        footer={[
          <Button loading={marketPlaceSavingDraft} type="primary" ghost onClick={this.saveMarketplaceAppDraft}>Save draft</Button>,
          !showPreview ? <Button type="primary" ghost onClick={this.showPreview}>Show Preview</Button> : null,
          <Button loading={marketplacePublish} type="primary" onClick={this.publishMarketplaceApp}>Publish</Button>
        ]}
      >
        {!showPreview && this.renderPublishForm()}
        {showPreview && this.renderReview()}
      </Modal>
    );
  }
}

export default withRouter(PublishProjectToMarketplaceModal);
