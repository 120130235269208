import React from 'react';
// import { Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import {Row, Input, Icon, Col, Popconfirm, Empty, Pagination, Select, Spin, Tooltip} from 'antd';
import CreateFieldModal from '../processBuilder/formBuilder/createFieldModal';
import {deleteFieldRequest, fetchMoreFieldsRequest} from './duck/FieldActions'
import {showNotification} from '../utils/commonFunctions'

class FieldContainer extends React.Component {

    state = {
        showModal   : false,
        activeField : null,
        searchText  : '',
        sortBy      : [],
        current:1
    }

    chooseIcon = (type) => {
        switch(type){
            case 'text' : return 'font-colors';
            case 'number' : return 'number';
            case 'telephone' : return 'phone';
            case 'dropdown' : return 'down-circle';
            case 'textarea' : return 'align-left';
            case 'date' : return 'calendar';
            case 'url' : return 'global';
            case 'file' : return 'cloud-upload';
            default : break;
        }
    
    }

    toggleModal = (val) => {
        this.setState({showModal:val})
    }

    deleteField = (id) => {
        deleteFieldRequest(id)
        showNotification('success', 'Field deleted successfully')
    }

    editField = (field) => {
        this.setState({
            showModal   : true,
            activeField : field
        })
    }

    searchFields = (e) => {
        this.setState({searchText:e.target.value})
    }

    sort = (val) => {
        this.setState({sortBy:val})
    }

    // onChange = (pageNumber) => {
    //     console.log('Page: ', pageNumber);
    //     this.setState({
    //         current: pageNumber,
    //       });
    //     // fetchMoreFieldsRequest({limit:2,offset:pageNumber})
    // }

    render(){
        let fields   = this.props.fields && this.props.fields.data && this.props.fields.data.data
        // let totalCount  = this.props.fields && this.props.fields.meta.totalCount
        if(this.state.searchText.trim() !== ''){
            fields   = fields && fields.filter(f=>f.attributes.name.toLowerCase().includes(this.state.searchText.toLowerCase()))
        }
        if(this.state.sortBy === 'alphabetical'){
            let fld = [...fields]
            fields  = fld.sort((a, b) => a.attributes.name.localeCompare(b.attributes.name))
        }else if(this.state.sortBy === 'creation'){
            let fld = [...fields]
            fields  = fld.reverse()
        }
        return(
            <Spin spinning={this.props.fields.loading} style={{minHeight:'60vh'}} size='large'>
                <Row gutter={16} type='flex' justify='end' style={{marginBottom:'40px'}}>
                    <Col span={4}>
                        <Select
                            getPopupContainer={(e)=>e.parentNode}
                            style       = {{boxShadow: '0px 8px 16px rgba(169, 194, 209, 0.1)'}}
                            className   = 'fw custom-select white'
                            size        = 'large'
                            placeholder = 'Sort by'
                            defaultValue= {this.state.sortBy}
                            onChange    = {this.sort}
                            allowClear

                        >
                            <Select.Option value='alphabetical'>
                                <Icon type="sort-ascending" className='mr-10' style={{fontSize:'18px'}}/>
                                Alphabetical
                            </Select.Option>   
                            <Select.Option value='creation'>
                                <Icon type="calendar" className='mr-10'/>
                                Creation Date
                            </Select.Option>   
                        </Select>
                    </Col>
                    <Col span={6}>
                        <Input
                            placeholder="Search fields"
                            prefix={<Icon type="search" style={{fontSize:'20px', color: 'rgba(0,0,0,.25)' }} />}
                            size='large'
                            className='input-box searchbox'
                            onKeyUp = {this.searchFields}
                        />
                    </Col>
                </Row>
                {
                    fields && fields.length > 0
                    ?
                        fields.map((field, index)=>{
                            return  <Row className='fieldItem' key={field.id}> 
                                        <Row type='flex' justify='space-between'>
                                            <Col span={19}>
                                                <Row type='flex' align='middle'>
                                                    <Col span={4}>
                                                        <Icon type={this.chooseIcon(field.attributes.type)}/>
                                                    </Col>
                                                    <Col span={20} className='truncate'>
                                                        <Tooltip title={field.attributes.name}>
                                                            <span style={{color:'#56667A'}}>{field.attributes.name}</span>
                                                        </Tooltip>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={5} style={{background:'#fff'}}>
                                                <Row type='flex' justify='end' className='edit'>
                                                    <Icon type='edit' onClick={e=>this.editField(field)} />
                                                    <Popconfirm
                                                        title="Are you sure delete this field?"
                                                        onConfirm={e=>this.deleteField(field.id)}
                                                        // onCancel={cancel}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Icon type='delete' style={{marginRight:0}}/>
                                                    </Popconfirm>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Row>

                        })
                    :
                        <Row style={{minHeight:'50vh'}} type='flex' align='middle' justify='center'>
                            <Empty description={false} />
                        </Row>
                }
                {/* <Pagination defaultCurrent={this.state.current} total={totalCount} onChange={this.onChange} pageSize={2} /> */}
                <CreateFieldModal
                    toggleModal = {this.toggleModal}
                    showModal   = {this.state.showModal}
                    field       = {this.state.activeField}
                />
            </Spin>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        fields : state.fields
    }
}

export default connect(mapStateToProps)(FieldContainer);