import {all} from 'redux-saga/effects'
// import loginSaga from 'login/duck/LoginSagas';
import usersSaga from "../people/duck/UsersSagas";
import connectionSaga from "../utils/getConnectivityChannel";
// import checkTokenSaga from "config/CheckTokenSaga";
import profileSaga from "../profile/duck/ProfileSagas";
import teamsSaga from "../teams/duck/TeamsSagas";
import pagesSaga from '../pageBuilder/duck/PageSaga'
import processSage from '../process/duck/ProcessSaga'
import projectSaga from '../project/duck/ProjectSagas';
import datasetSaga from '../dataset/duck/DatasetSagas';
import fieldsSaga from '../fields/duck/FieldSaga';
import organisationSaga from '../organisation/duck/OrganisationSaga';
import categoriesSaga from '../categories/duck/CategoriesSagas'
import tagsSaga from '../tags/duck/TagsSaga';
import recordSaga from '../record/duck/RecordSagas';
import masterSaga from '../master/duck/MasterSagas';
import marketplaceAppSagas from '../marketplaceApp/duck/MarketplaceAppSagas';
// import handleException from 'exceptions/handleException';

function* rootSaga() {

    yield all([
        connectionSaga(),
        // checkTokenSaga(),
        // loginSaga(),
        profileSaga(),
        usersSaga(),
        teamsSaga(),
        pagesSaga(),
        fieldsSaga(),
        processSage(),
        projectSaga(),
        datasetSaga(),
        tagsSaga(),
        categoriesSaga(),
        recordSaga(),
        masterSaga(),
        marketplaceAppSagas(),
        organisationSaga()
    ])
}

export {rootSaga}
