import React from 'react';
import {Col, Input, Radio, Row, Tag} from "antd";
import FieldsList from "./FieldsList";
import AggregateOptionsBox from "./AggregateOptionsBox";
import AggregateFields from "./AggregateFields";

const YaxisSection = (props) => {
    let numberTypeFields = props.fieldList.filter(field=>field.type==='number')

    return (
        <Row style={props.rowStyle}>
            <label className='f15' style={{color:'#44566C'}}>Y-axis</label>
            <Row style={{margin:'10px 0'}}>
                <Col span={10}>Value Type</Col>
                <Col span={14}>
                    <Radio.Group onChange={(e)=>props.onChangeRadioBtn(e, 'valueType')} value={props.valueType}>
                        <Radio value="Aggregate">Aggregate</Radio>
                        <Radio value="Actual">Actual</Radio>
                    </Radio.Group>
                </Col>
            </Row>
            {
                props.valueType === 'Aggregate' ?
                    <AggregateFields selectedAggregateFields={props.selectedAggregateFields} onSelectAggregateOption={props.onSelectAggregateOption} /> :
                    <FieldsList fetchingFields={props.fetchingFields} mode="multiple" fieldList={props.fieldList} onSelectFields={props.onSelectFields} selectedFields={props.selectedFields}/>
            }
            <Input placeholder="Y-axis label" className='custom-input white' allowClear size='large' value={props.yAxisLabel} onChange={e=>props.onChangeLabel(e,"yAxis")}/>
            {props.valueType === 'Aggregate' &&
                <AggregateOptionsBox rowStyle={props.rowStyle} numberTypeFields={numberTypeFields}
                                 selectedAggregateFields={props.selectedAggregateFields} onSelectAggregateOption={props.onSelectAggregateOption}
            />}
        </Row>
    );
};

export default YaxisSection;
