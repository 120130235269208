import {combineReducers} from "redux";
import profileReducer from '../profile/duck/ProfileReducer';
import usersReducer from "../people/duck/UsersReducer";
import teamsReducer from "../teams/duck/TeamsReducer";
import activePageTabReducer from "../navigation/duck/PageNavigationReducer";
import pagesReducer from '../pageBuilder/duck/PageReducer';
import processReducer from '../process/duck/ProcessReducer'
import projectReducer from "../project/duck/ProjectReducer";
import dataSetReducer from "../dataset/duck/DatasetReducer";
import fieldReducer from "../fields/duck/FieldReducer";
import organisationReducer from "../organisation/duck/OrganisationReducer";
import categoriesReducer from '../categories/duck/CategoriesReducer'
import tagsReducer from '../tags/duck/TagsReducer';
import recordReducer from '../record/duck/RecordReducer';
import masterReducer from '../master/duck/MasterReducer';
import marketPlaceAppReducer from '../marketplaceApp/duck/MarketplaceAppReducer';

const appReducer = combineReducers({
    currentUser     : profileReducer,
    users           : usersReducer,
    teams           : teamsReducer,
    pages           : pagesReducer,
    process         : processReducer,
    activePage      : pagesReducer,
    fields          : fieldReducer,
    activePageTab   : activePageTabReducer,
    project         : projectReducer,
    categories      : categoriesReducer,
    dataset         : dataSetReducer,
    tags            : tagsReducer,
    marketplaceApp  : marketPlaceAppReducer,
    record          : recordReducer,
    master          : masterReducer,
    organisations   : organisationReducer
    // inviteUsersResponse: inviteUsersReducer,
})

const rootReducer = (state,action) => {
    // clearing redux state when user logs out
    if(action.type === 'LOGOUT_SUCCESS'){
        state = undefined;
    }

    return appReducer(state,action);
}

export default rootReducer;
