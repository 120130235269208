import React from 'react';
import {Row, List, Dropdown, Spin, Menu, Button, Input, Select, Icon, Checkbox, Col, Divider, Tooltip} from 'antd';
import AddNewButton from "../../utils/addNewButton";


const {Option} = Select;

class RecordHeader extends React.Component {
    constructor(props) {
        super(props);
        this.datasetNameRef = React.createRef();
        this.datasetDescriptionRef = React.createRef();
        this.state = {
            enableDatasetNameEdit: false,
            enableDatasetDescriptionEdit: false,
            datasetName: props.data.attributes.name,
            datasetDescription: props.data.attributes.description
        }
    }

    enableDatasetNameEdit = () => {
        this.setState({
            enableDatasetNameEdit: true
        }, () => {
            this.datasetNameRef.current.focus();
        })
    }

    enableDatasetDescriptionEdit = () => {
        this.setState({
            enableDatasetDescriptionEdit: true
        }, () => {
            this.datasetDescriptionRef.current.focus();
        });
    }

    updateDatasetName = (e) => {
        this.setState({
            datasetName: e.target.value
        })
    }
    updateDataset = () => {
        const data = {
            name: this.state.datasetName,
            description: this.state.datasetDescription
        }
        this.props.updateDataset(data);
        this.setState({
            enableDatasetNameEdit: false,
            enableDatasetDescriptionEdit: false
        })
    }

    updateDatasetDescription = (e) => {
        this.setState({
            datasetDescription: e.target.value
        })
    }

    render() {
        let editable = false, props = this.props;
        let {enableDatasetNameEdit, enableDatasetDescriptionEdit, datasetName, datasetDescription} = this.state;

        return (
            <Col>
                <Row type='flex' justify='space-between'>
                <span style={{marginLeft: '10px'}}>
                    <Row type='flex' justify='start' align={'middle'}>
                        <Col style={{marginLeft: 0}}>
                             <Row>
                            {
                                enableDatasetNameEdit && <Input
                                    maxLength={35}
                                    style={{width: "100%"}}
                                    onChange={this.updateDatasetName}
                                    value={datasetName}
                                    ref={this.datasetNameRef}
                                    onKeyDown={e => e.key === 'Enter' && this.updateDataset()}
                                    onBlur={this.updateDataset}
                                />}
                                 {!enableDatasetNameEdit && <span
                                     style={{
                                         fontSize: '20px',
                                         lineHeight: '18px',
                                         color: '#44566C',
                                         fontWeight: 'bold',
                                         marginRight: '8px'
                                     }}
                                     onClick={this.enableDatasetNameEdit}
                                 >
                {datasetName}
            </span>}
                            </Row>
                            {enableDatasetDescriptionEdit && <Input
                                value={datasetDescription}
                                onChange={this.updateDatasetDescription}
                                style={{width: "80%"}}
                                ref={this.datasetDescriptionRef}
                                onKeyDown={e => e.key === 'Enter' && this.updateDataset()}
                                onBlur={this.updateDataset}
                            />}
                            {!enableDatasetDescriptionEdit && props.data.attributes.description && <span
                                style={{fontSize: '12px', lineHeight: '18px', color: '#8798A8'}}
                                onClick={this.enableDatasetDescriptionEdit}
                            >
                            {datasetDescription}
            </span>}
                            {!enableDatasetDescriptionEdit && !props.data.attributes.description && <span
                                style={{fontSize: '12px', lineHeight: '18px', color: '#8798A8'}}
                                onClick={this.enableDatasetDescriptionEdit}
                            >
              Add description
            </span>}
                        </Col>
                        <Dropdown
                            trigger={["click"]}
                            overlay={
                                <Menu onClick={(e) => props.handleMenu(props.data.id, e)}>
                                    <Menu.Item key="edit">
                                        <a>Edit form</a>
                                    </Menu.Item>
                                    <Menu.Item key="delete">
                                        <a style={{color: "#FF3D57"}}>Delete</a>
                                    </Menu.Item>
                                </Menu>
                            }>
                            <Icon type="more"
                                  style={{fontSize: 20, cursor: "pointer", align: 'middle', marginLeft: '15px'}}/>
                        </Dropdown>
                    </Row>
                </span>
                </Row>
                <Row type='flex' justify='space-between' style={{marginTop: 20}}>
                <span style={{marginLeft: '10px'}}>
                    Show
                     <Select getPopupContainer={(e) => e.parentNode} defaultValue="10"
                             style={{width: 70, marginLeft: 7}} onChange={props.paginationSet}>
                        <Option value="10">10</Option>
                        <Option value="20">20</Option>
                        <Option value="50">50</Option>
                        <Option value="100">100</Option>
                     </Select>
                </span>
                    <span style={{marginRight: 20}}>
                    {props.showDelete ?
                        <span>
                            {/*<AddNewButton disabled={editable} trigger={props.edit} icon={'edit'}/>*/}
                            <AddNewButton disabled={editable} trigger={props.delete} icon={'delete'}/>
                        </span>
                        : <AddNewButton disabled={editable} trigger={() => props.create(true)}/>}
                </span>
                </Row>

            </Col>
        );

    }
}

export default RecordHeader;