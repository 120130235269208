import React from 'react';
// import {Route} from 'react-router-dom';
// import { Spin } from 'antd/lib/index';
// import PageNotFound from "../exceptions/PageNotFound";
// import App from "../App";
// import AppLayout from "./AppLayout";
import PrivateRoute from "./PrivateRoute";
import WorkflowContainer from "../workflow/WorkflowContainer";
import DashboardContainer from "../dashboard/DashboardContainer";
import UsersContainer from "../people/UsersContainer";
// import TeamsContainer from "../teams/TeamsContainer";
// import PageBuilderContainer from "../pageBuilder/pageBuilderContainer";
import ProcessListContainer from "../process/processListContainer";
import ProcessBuilderContainer from "../processBuilder/processBuilderContainer";
import ProjectContainer from '../project/ProjectContainer'
import DataSetContainer from '../dataset/DatasetContainer'
import RecordListContainer from '../dataset/RecordListContainer'
import FieldContainer from '../fields/fieldContainer'
import TagContainer from '../tags/tagContainer';
// import MasterContainer from '../master/masterContainer'
// import FormBuilderContainer from '../processBuilder/formBuilder/formBuilderContainer'
import ProjectListContainer from '../project/ProjectListContainer';
import DatasetListContainer from '../dataset/DatasetListContainer';
// import SignupDetailsContainer from '../signup/signup_details/SignupDetailsContainer';
// import {initializeSocket} from "./SocketInit";
// const WorkflowContainer = React.lazy(() => {
// });
// const DashboardContainer = React.lazy(() => import('../dashboard/DashboardContainer'));
//
// <Route exact path='/' component={DashboardContainer} />
// <Route path='/workflows' component={WorkflowContainer} />
{/* <Route component={PageNotFound} /> */}



const AppRouters = (props) => {

    return <React.Fragment>
        <PrivateRoute path='/app/dashboard' component={DashboardContainer}/>
        <PrivateRoute path='/app/workflows' component={WorkflowContainer}/>
        <PrivateRoute path='/app/users' component={UsersContainer}/>
        {/*<PrivateRoute path='/signup-details' component={SignupDetailsContainer}/>*/}
        {/*<PrivateRoute path='/app/teams' component={TeamsContainer}/>*/}
         {/*<PrivateRoute path='/app/pages' component={PageBuilderContainer}/>*/}
        <PrivateRoute path='/app/processes' exact component={ProcessListContainer}/>
        {/* <PrivateRoute path='/app/process/creator' exact component={ProcessBuilderContainer}/> */}
        <PrivateRoute path='/app/process/creator/:id' component={ProcessBuilderContainer}/>
        <PrivateRoute path='/app/fields' component={FieldContainer}/>
        <PrivateRoute path='/app/tags' component={TagContainer}/>
        {/*<PrivateRoute path='/app/master' component={MasterContainer}/>*/}

        <PrivateRoute path='/app/dataset' exact component={DatasetListContainer}/>
        <PrivateRoute path='/app/form/edit/dataset/:id' component={DataSetContainer}/>
        <PrivateRoute path='/app/dataset/:id' exact component={RecordListContainer}/>
        <PrivateRoute path='/app/projects' exact component={ProjectListContainer}/>
        <PrivateRoute path='/app/projects/:id' exact component={ProjectContainer}/>
        <PrivateRoute path='/app/form/edit/project/:id' exact component={ProcessBuilderContainer}/>
    </React.Fragment>;
};

export default AppRouters;
