import React from 'react';
import { Row, Icon, Col, Select, Input, Button } from 'antd';
import YourProjectsList from '../YourProjectsList/';
import Marketplace from '../Marketplace/';
import { withRouter } from 'react-router-dom';

import './styles.css';

const YOUR_PROJECTS = 'YOUR_PROJECTS';
const MARKETPLACE = 'MARKETPLACE';

class ProjectListComponent extends React.Component {

  state = {
    projectType: YOUR_PROJECTS,
    loadingYouProjects: true,
    loadingMarketplace: true,
  }

  componentDidMount() {
    const match = this.props.location.search.match(/panel=(\w+)&?/);
    if (match && (match[1].toLowerCase() === YOUR_PROJECTS.toLowerCase() || match[1].toLowerCase() === MARKETPLACE.toLowerCase()) ) {
      this.setState({
        projectType: match[1].toUpperCase()
      })
    }
    this.props.fetchMarketplaceProjects();
    this.props.fetchProjectTemplates();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.templates !== this.props.templates) {
      this.setState({
        loadingYouProjects: false
      });
    }
    if (prevProps.marketplaceProjects !== this.props.marketplaceProjects) {
      this.setState({
        loadingMarketplace: false
      });
    }
  }

  toggleProjectType = (type) => {
    this.setState({
      projectType: type
    });
    this.props.history.push(`/app/projects?panel=${type}`)
  }

  sortProjects = (value) => {
    switch (value) {
      case 'alphabetical':
        this.setState({
          loadingYouProjects: true
        })
        return this.props.fetchProjectTemplates({
          sort: "name",
          order: "asc"
        });
      case 'creation':
      case undefined:
        this.setState({
          loadingYouProjects: true
        })
        return this.props.fetchProjectTemplates();
    }
  }

  renderHeader() {

    const { projectType } = this.state;

    return (
      <Row gutter={16} type='flex' style={{marginBottom:'40px'}}>
        <Col span={20}>
          {projectType !== YOUR_PROJECTS && <a
            onClick={() => this.toggleProjectType(YOUR_PROJECTS)}
            style={{color: "#44566C", padding: "0px 15px"}}
          >
            Your Projects
          </a>}
          {projectType === YOUR_PROJECTS &&  <Button
            className="project-header-toggle"
          >
            Your Projects
          </Button>}
          {projectType !== MARKETPLACE && <a
            onClick={() => this.toggleProjectType(MARKETPLACE)}
            style={{color: "#44566C", padding: "0px 15px"}}
          >
            Marketplace
          </a>}
          {projectType === MARKETPLACE &&  <Button
            className="project-header-toggle"
          >
            Marketplace
          </Button>}
        </Col>
        <Col span={4}>
          {projectType === YOUR_PROJECTS && <Select
            getPopupContainer={(e)=>e.parentNode}
            style       = {{boxShadow: '0px 8px 16px rgba(169, 194, 209, 0.1)'}}
            className   = 'fw custom-select white'
            size        = 'large'
            placeholder = 'Sort by'
            onChange    = {this.sortProjects}
            allowClear
            defaultValue="creation"

          >
            <Select.Option value='alphabetical'>
              <Icon type="sort-ascending" className='mr-10' style={{fontSize:'18px'}}/>
              Alphabetical
            </Select.Option>
            <Select.Option value='creation'>
              <Icon type="calendar" className='mr-10'/>
              Creation Date
            </Select.Option>
          </Select>}
        </Col>
        {/*<Col span={4}>
          <Input
            placeholder="Search projects"
            prefix={<Icon type="search" style={{fontSize:'20px', color: 'rgba(0,0,0,.25)' }} />}
            size='large'
            className='input-box searchbox'
            onKeyUp = {this.searchProject}
          />
        </Col>*/}
      </Row>
    );
  }

  render() {
    const { projectType, loadingYouProjects, loadingMarketplace } = this.state;
    const { marketplaceProjects, installApp, templates } = this.props;

    return (
      <>
        {this.renderHeader()}
        {projectType === MARKETPLACE && <Marketplace
          loggedInuser={this.props.loggedInuser}
          apps={marketplaceProjects}
          loading={loadingMarketplace}
          installApp={installApp}
          installAppFailed={this.props.installAppFailed}
          installAppSuccess={this.props.installAppSuccess}
          updateMarketplaceApp={this.props.updateMarketplaceApp}
        />}
        {projectType === YOUR_PROJECTS && <YourProjectsList
          projects={templates}
          loading={loadingYouProjects}
        />}
      </>
    );
  }
}

export default withRouter(ProjectListComponent);
