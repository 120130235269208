import React from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import { Container, Draggable } from "react-smooth-dnd";
import { Skeleton, message } from 'antd';
import { fetchProject, updateProject, updateProjectTemp, createTask, deleteProject } from './duck/ProjectActions';
// import { fetchTasksSuccess, updateTask, deleteTask } from '../tasks/duck/TasksActions';
import Header from './components/Header/';
import Column from './components/Column/';
import NewLaneForm from './components/NewLaneForm';
import { colorArr } from '../utils/commonFunctions';
import { fetchCategories } from '../categories/duck/CategoriesActions';
import { createMarketplaceApp, fetchMarketplaceApp, updateMarketplaceApp } from '../marketplaceApp/duck/MarketplaceAppActions';

const colors = colorArr.map(color => {
  return {
    colorDark: color,
    colorLight: `${color}24`
  };
});

class ProjectContainer extends React.Component {

  state = {
    loading: true,
    project: undefined,
    showCreateNewColumn: false,
    createdTaskColumnIndex: undefined,
    createdTaskSegmentIndex: undefined,
    dummyTasks: [],
    unsavedProject: false,
  }

  componentDidMount() {
    const { match } = this.props;
    const {params: {id}} = match;
    this.props.fetchCategories();
    if (!(this.props.project && this.props.project._id === id)) {
      this.props.fetchProject(id);
    } else {
      this.setState({
        loading: false,
        project: this.props.project,
      });
      if (!this.props.project.app_id) {
        this.createMarketplaceApp();
      } else {
        this.fetchMarketplaceApp(this.props.project.app_id);
      }
      const { tasks={} } = this.props;
      // this.props.fetchTasksSuccess(
      //   this.props.project.tasks.reduce((result, task) => {
      //     if (!tasks[task._id]) {
      //       result[task._id] = task
      //     }
      //     return result;
      //   }, {})
      // );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.loading && this.props.project !== prevProps.project) {
      this.setState({
        loading: false,
        project: this.props.project,
      })
      if (!this.props.project.app_id) {
        this.createMarketplaceApp();
      } else {
        this.fetchMarketplaceApp(this.props.project.app_id);
      }
      const { tasks={} } = this.props;
      // this.props.fetchTasksSuccess(
      //   this.props.project.tasks.reduce((result, task) => {
      //     if (!tasks[task._id]) {
      //       result[task._id] = task
      //     }
      //     return result;
      //   }, {})
      // );
    }
    if (this.props.projectDeleted !== prevProps.projectDeleted && this.props.projectDeleted) {
      this.props.history.push("/app/projects");
    }
    if (this.props.createApp && this.props.createApp !== prevProps.createApp) {
      const project = Object.assign({}, this.props.project);
      project.app_id = this.props.createApp;
      this.props.updateProject(project);
    }
    if (this.props.project !== prevProps.project) {
      this.setState({
        project: this.props.project,
      });
    }
    if (this.props.match.url !== prevProps.match.url) {
      const { match } = this.props;
      const {params: {id}} = match;
      this.setState({
        loading: true
      })
      this.props.fetchProject(id);
    }
    if (this.props.createdTask !== prevProps.createdTask) {
      const project = Object.assign({}, this.state.project);
      const { createdTaskSegmentIndex, createdTaskColumnIndex } = this.state;
      const { createdTask, createdTaskDummyId } = this.props;
      const column = project.columns[createdTaskColumnIndex];
      const segment = column.segments[createdTaskSegmentIndex];
      segment.tasks = segment.tasks.filter((task) => task !== createdTaskDummyId)
      segment.tasks.splice(0, 0, createdTask._id);
      project.tasks = project.tasks.filter(task => task.id !== createdTaskDummyId);
      project.tasks.push(createdTask);
      this.setState({
        project,
      })
      this.updateProject(project);
      // this.props.fetchTasksSuccess({[createdTask._id]: createdTask})
    }
  }

  fetchMarketplaceApp = (id) => {
    const { loggedInuser } = this.props;
    if (loggedInuser.attributes.role_id !== "super_admin")  return;
    this.props.fetchMarketplaceApp(id);
  }

  createMarketplaceApp = () => {
    const { project, loggedInuser } = this.props;
    if (loggedInuser.attributes.role_id !== "super_admin")  return;
    const app = {
      type: "Project",
      meta: {},
      billing_type: "free",
      data: project,
      draft: true,
    };
    this.props.createMarketplaceApp({
      data: app
    });
  }

  updateProject = (project) => {
    // const { loggedInuser } = this.props;
    // if (project.owner.reduce((result, user) => result || user._id === loggedInuser.id, false) ||
    //   project.edit.reduce((result, user) => result || user._id === loggedInuser.id, false)
    // ) {
    this.setState({project, unsavedProject: true});
    //this.props.updateProjectTemp(project);
    // } else {
    //   message.error('You are not allowed to make this action.');
    // }
  }

  saveDraft = () => {
    const project = Object.assign({}, this.state.project);
    project.draft = true;
    const marketplaceApp = Object.assign({}, this.props.marketplaceApp);
    marketplaceApp.data = project;
    this.setState({unsavedProject: false});
    this.props.updateProject(project);
      //this.props.updateMarketplaceApp({data: marketplaceApp});
  }

  publishProject = () => {
    const project = Object.assign({}, this.state.project);
    project.draft = false;
    const marketplaceApp = Object.assign({}, this.props.marketplaceApp);
    marketplaceApp.data = project;
    this.setState({unsavedProject: false});
    this.props.updateProject(project);
      //this.props.updateMarketplaceApp({data: marketplaceApp});
  }

  applyDrag = (arr, dragResult) => {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) return arr;

    const result = [...arr];
    let itemToAdd = payload;

    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0];
    }

    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd);
    }

    return result;
  };

  handleColumnDrop = (dropResult) => {
    const { removedIndex, addedIndex } = dropResult;
    const project = Object.assign({}, this.state.project);
    project.columns = this.applyDrag(project.columns, dropResult)
    this.setState({
      project
    })
    this.updateProject(project);
  }

  handleRemoveTask = (columnIndex, segmentIndex, removedIndex) => {
    const project = Object.assign({}, this.state.project);
    const oldSegment = project.columns[columnIndex].segments[segmentIndex];
    const tasks = oldSegment.tasks.slice(0, removedIndex).concat(oldSegment.tasks.slice(removedIndex+1));
    project.columns[columnIndex].segments[segmentIndex] = Object.assign(oldSegment, {tasks});
    this.setState({
      project
    });
    this.updateProject(project);
  }

  handleAddTask = (columnIndex, segmentIndex, addedIndex, taskId) => {
    const project = Object.assign({}, this.state.project);
    const oldSegment = project.columns[columnIndex].segments[segmentIndex];
    const tasks = oldSegment.tasks.slice(0, addedIndex).concat([taskId]).concat(oldSegment.tasks.slice(addedIndex));
    project.columns[columnIndex].segments[segmentIndex] = Object.assign({}, oldSegment, {tasks});
    this.setState({
      project
    });
    this.updateProject(project);
  }

  showCreateNewColumn = () => {
    this.setState({
      showCreateNewColumn: true
    })
  }

  hideCreateNewColumn = () => {
    this.setState({
      showCreateNewColumn: false
    })
  }

  createNewColumn = (column) => {
    const project = Object.assign({}, this.state.project);
    project.columns = project.columns || [];
    project.columns.push({...column, index: project.columns.length});
    this.setState({
      project,
      showCreateNewColumn: false
    })
    this.updateProject(project);
  }

  updateColumm = (column, index) => {
    const project = Object.assign({}, this.state.project);
    project.columns[index] = column;
    this.setState({
      project
    })
    this.updateProject(project);
  }

  handleColumnDelete = (columnIndex) => {
    const project = Object.assign({}, this.state.project);
    const columns = project.columns;
    project.columns = columns
      .slice(0, columnIndex)
      .concat(columns.slice(columnIndex+1))
    this.setState({
      project
    });
    this.updateProject(project);
  }

  createTask = (task, columnIndex, segmentIndex) => {
    const { project, dummyTasks } = this.state;
    const newProject = Object.assign({}, project);
    const column = newProject.columns[columnIndex];
    const segment = column.segments[segmentIndex];
    // to add fields in task
    const fid = {}
    if(project.fields && Object.keys(project.fields).length > 0){
      Object.keys(project.fields).map(fieldId => {
        fid[fieldId] = {
          value : {
            row_id : null
          }
        }
      })
    }
    task.fields = fid;

    const createdTask = {
      ...task,
      project_id: newProject._id,
      type: "tasks",
      _id: dummyTasks.length
    }
    segment.tasks.splice(0, 0, createdTask._id);
    newProject.tasks.push(createdTask);

    this.setState({
      createdTaskColumnIndex: columnIndex,
      createdTaskSegmentIndex: segmentIndex,
      dummyTasks: [...dummyTasks, createdTask._id],
      project: newProject,
    });
    this.props.createTask({
      data: {
        ...task,
        project_id: newProject._id
      }
    }, createdTask._id)
    // this.props.fetchTasksSuccess({
    //   [createdTask._id]: createdTask
    // })
  }

  deleteTask = (columnIndex, segmentIndex, taskIndex, id) => {
    const project = Object.assign({}, this.props.project);
    const column = project.columns[columnIndex];
    const segment = column.segments[segmentIndex];
    segment.tasks = segment.tasks.slice(0, taskIndex).concat(segment.tasks.slice(taskIndex+1));
    this.updateProject(project);
    this.props.deleteTask(id);
  }

  renderKanban = () => {
		const { project, showCreateNewColumn } = this.state;
		const { columns } = project;
    const { tasks } = this.props;

		return (
			<Container
				style={{height: "100%"}}
				orientation="horizontal"
				dragHandleSelector=".column"
				onDrop={this.handleColumnDrop}
			>
				{
					columns && columns.map((column, columnIndex) => {
            //const color = colors[columnIndex % 15];
            const color = {
              colorDark: "#0081FF",
              colorLight: "#0081FF24"
            };
						return (
							<Column
								key = {columnIndex}
								tasks={tasks}
								createTask={this.createTask}
								column={column}
								color={color}
								columnIndex={columnIndex}
								updateColumm={this.updateColumm}
								removeTask={this.handleRemoveTask}
								addTask={this.handleAddTask}
								onDelete={this.handleColumnDelete}
                updateTask={this.props.updateTask}
                deleteTask={this.deleteTask}
                users={this.props.users}
                loggedInuser={this.props.loggedInuser}
							/>
						);
					})
				}
				{
					showCreateNewColumn &&
					<Draggable key={-1}>
						<div style={{width: 324, paddingRight: 24}}>
							<NewLaneForm onCancel={this.hideCreateNewColumn} onAdd={this.createNewColumn} />
						</div>
					</Draggable>
				}
        {!showCreateNewColumn && <Draggable
					key={-2}
					style={{color: "#0081FF"}}
				>
          <div
            style={{width: 324, paddingRight: 24, cursor: "pointer"}}
            onClick={this.showCreateNewColumn}
          >
            + Add Column
          </div>
				</Draggable>}
			</Container>
		);
  }

  renderSkeleton = () => {
    return (
      <div>
        <Skeleton paragraph={{rows: 1, width: "100%"}} active></Skeleton>
        <div style={{marginTop: 10}}></div>
        <div style={{display: "flex"}}>
          <Skeleton title={{width: "70%"}} paragraph={{rows: 5, width: "70%"}} active></Skeleton>
          <div style={{width: 700}}></div>
          <Skeleton title={{width: "70%"}} paragraph={{rows: 5, width: "70%"}} active></Skeleton>
          <div style={{width: 700}}></div>
          <Skeleton title={{width: "70%"}} paragraph={{rows: 5, width: "70%"}} active></Skeleton>
          <div style={{width: 700}}></div>
          <Skeleton title={{width: "70%"}} paragraph={{rows: 5, width: "70%"}} active></Skeleton>
        </div>
      </div>
    );
  }

  deleteProject = (id) => {
    this.props.deleteProject(id);
  }

	render() {
    const { project, loading, unsavedProject } = this.state;
    const {
      users,
      updatedApp,
      updatedAppFail,
      updateMarketplaceApp,
      updatedProject,
      marketplaceApp,
      loggedInuser,
      categories
    } = this.props;
		return (
			<div style={{height: "100%"}}>
        <Prompt when={unsavedProject} message="Changes that you made to project are not saved! Are you sure you want to leave?"/>
        {project && loading && this.renderSkeleton()}
        {project && !loading && users && <Header
          project={project}
          updateProject={this.updateProject}
          saveDraft={this.saveDraft}
          publishProject={this.publishProject}
          users={users}
          loggedInuser={loggedInuser}
          deleteProject={this.deleteProject}
          updatedProject={updatedProject}
          categories={categories}
          marketplaceApp={marketplaceApp}
          updateMarketplaceApp={updateMarketplaceApp}
          updatedApp={updatedApp}
          updatedAppFail={updatedAppFail}
        />}
          <div style={{overflowX: "scroll", height: "93%"}}>
            {project && !loading && this.renderKanban()}
          </div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		project: state.project.active && state.project.projects[state.project.active],
		createdTask: state.project.createdTask,
		createdTaskDummyId: state.project.createdTaskDummyId,
    tasks: null,
    users: state.users && state.users.data && state.users.data.data,
    loggedInuser: state.currentUser && state.currentUser.data && state.currentUser.data.data,
    updatedProject: state.project.updatedProject,
    projectDeleted: state.project.projectDeleted,
    categories: state.categories.categories && Object.values(state.categories.categories),
    createApp: state.marketplaceApp.createdApp,
    updatedApp: state.marketplaceApp.updatedApp,
    updatedAppFail: state.marketplaceApp.updatedAppFail,
    marketplaceApp: state.marketplaceApp.apps && state.project.active && state.project.projects[state.project.active] &&
      state.marketplaceApp.apps[state.project.projects[state.project.active].app_id],
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchProject: (id) => dispatch(fetchProject(id)),
		// fetchTasksSuccess: (tasks) => dispatch(fetchTasksSuccess(tasks)),
		updateProjectTemp: (project) => dispatch(updateProjectTemp(project)),
		updateProject: (project) => dispatch(updateProject(project)),
		createMarketplaceApp: (app) => dispatch(createMarketplaceApp(app)),
		fetchMarketplaceApp: (id) => dispatch(fetchMarketplaceApp(id)),
		// createTask: (task, dummyId) => dispatch(createTask(task, dummyId)),
    // updateTask: (task) => dispatch(updateTask(task)),
    // deleteTask: (id) => dispatch(deleteTask(id)),
    deleteProject: (id) => dispatch(deleteProject(id)),
    fetchCategories: () => dispatch(fetchCategories()),
    updateMarketplaceApp: (marketplaceApp) => dispatch(updateMarketplaceApp(marketplaceApp)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectContainer);
