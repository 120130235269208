import React, { Component } from 'react'
import {Row, Icon, Input, Radio, Button, notification} from 'antd'
import ProcessList from "./ProcessList";
import ProcessField from "./ProcessField";
import ChartList from "./ChartList";
import axios from 'axios';
import appUrl from "../../constants/AppUrl";
import XaxisSection from "./XaxisSection";
import YaxisSection from "./YAxisSection";
import EvaluationSection from "./EvaluationSection";
import {showNotification} from "../../utils/commonFunctions";

const mb      = {marginBottom:'20px'}
const query   = {background:'#F8FAFB',padding:'12px',borderRadius:'5px',color:'#44566C',marginBottom:'20px'}
const axisContainer = {
    backgroundColor:'#EAEDF0',
    padding:'14px',
    borderRadius:'5px'
}

// const chartList = ['Bar', 'Line', 'Pie']
const chartList = [{
    type : 'Bar',
    icon : 'bar-chart'
},
{
    type : 'Line',
    icon : 'line-chart'
},
{
    type : 'Pie',
    icon : 'pie-chart'
}]

export default class ChartConfig extends Component {

    state = {
        templateList    : [],
        processFields: [],
        fieldList       : [],
        reportName      : '',
        data : {
            process:null,
            form:null,
            chart:null,
            xAxis:{
                label:null,
                fields:[]
            },
            yAxis:{
                label:null,
                aggregate:[],
                valueType:null,
                fields:[]
            },
            evaluate:'All Records',
        }
    }


    componentDidMount() {
        if(this.props.selectedGraph) {
            this.setState({data: this.props.selectedGraph.data.attributes.query,reportName:this.props.selectedGraph.data.attributes.name})
        }
    }


    componentDidUpdate(prevProps, prevState){
        // console.log('prevProps', prevProps, this.props)
        if(this.props.selectedGraph) {
            if (!this.state.data.chart) {
                this.setState({data: this.props.selectedGraph.data.attributes.query})
            }else{
                if(this.props.selectedGraph.data.id !== prevProps.selectedGraph.data.id) {
                    this.setState({data: this.props.selectedGraph.data.attributes.query})
                }
            }
        }
    }

    onSelectProcess = (selectedPS) => {
        let {data} = this.state;
        data = {
            ...data, xAxis: {
                label: null,
                fields: []
            },
            yAxis: {
                label: null,
                aggregate: [],
                valueType: null,
                fields: []
            }
        };
        data.form = null;
        data.process={key:selectedPS.id, label:selectedPS.attributes.name};
        let processFields = []
        for (let [key, value] of Object.entries(selectedPS.attributes.fields)) {
            processFields.push({
                key: key,
                label: value.name,
                type: value.type,
                dataType: value.dataType
            })
        }
        console.log('FieldList: ',processFields)
        this.setState({processFields: processFields, data:data})
    }

    onSelectField = (selectedField, processFields) => {
        if (selectedField) {
            let {data} = this.state;
            data = {...data};
            data.form = {key: selectedField.key, label: selectedField.label};
            // console.log('selectedTemplate', selectedField, processFields, data)
            // let fieldList = selectedField.attributes.fields.map(field => ({
            //     label: field.label || field.default,
            //     key: field.fid,
            //     type: field.type
            // }));
            this.setState({selectedField,fieldList: processFields ? processFields : this.state.fieldList, data, fetchingFields: false})
        } else {
            this.setState({fetchingFields: true})
        }
    }

    onSelectFieldsForXaxis = (selectedFields) => {
        let {data} = this.state;
        data = {...data};
        data.xAxis.fields = selectedFields;
        // console.log('onSelectFieldsForXaxis', selectedFields)
        this.setState({data})
    }

    onSelectFieldsForYaxis = (selectedFields, action) => {
        let {data} = this.state;
        data = {...data};
        if(action === 'DELETE'){
            data.yAxis.fields = data.yAxis.fields.filter(field=>field.key !== selectedFields)
        }else{
            data.yAxis.fields = [...data.yAxis.fields, selectedFields];
        }
        // console.log('onSelectFieldsForYaxis', selectedFields, action, data)
        this.setState({data})
    }

    onSelectAggregateOption = (aggregateObj, action) => {
        let {data} = this.state;
        if(action === 'DELETE') {
            data.yAxis.aggregate = data.yAxis.aggregate.filter(field=>!((field.key === aggregateObj.key) && (field.operation === aggregateObj.operation)))
        }else{
            data.yAxis.aggregate = [...data.yAxis.aggregate, aggregateObj];
        }
        console.log('aggregateObj', aggregateObj, action)
        this.setState({data})
    }

    onSelectChart = (selectedCharts) => {
        let {data} = this.state;
        data = {...data};
        let _sel = {
            key : selectedCharts.key,
            label : selectedCharts.label[1]
        }
        data.chart          = _sel;
        // data..type = _sel.label;
        this.setState({data,chartNotSelected:false})
    }

    onChangeLabel = (e,axisType) => {
        let {data} = this.state;
        data = {...data};
        data[axisType].label = e.target.value;
        this.setState({data})
    }

    onSubmitData = () => {
        let {data, reportName} = this.state;
        let {selectedGraph} = this.props;

        if (data.chart) {
            if (selectedGraph) {
                let chart = {
                    data: {
                            name: reportName,
                            chart: data.chart.key,
                            workflow_id: null,
                            draft: false,
                            query: data,
                            style: {}
                    }
                }
                console.log('data', chart)
                axios({
                    url: appUrl.REPORTS + '/' + selectedGraph.data.id,
                    method: 'PUT',
                    data: chart
                }).then(res => {
                    console.log('res', res)
                    if (res.data.data) {
                        this.props.onUpdateChartData(data, res.data.data.id);
                        this.props.resetActiveTab();
                        showNotification('success', 'Report updated successfuly' )
                    } else {
                        showNotification('error', res.data.message )
                    }
                })
            } else {
                let chart = {
                    data: {
                            name: reportName,
                            chart: data.chart.key,
                            workflow_id: null,
                            draft: false,
                            query: data,
                            style: {}
                    }
                }
                // console.log('data', chart)
                axios({
                    url: appUrl.REPORTS,
                    method: 'POST',
                    data: chart
                }).then(res => {
                    console.log('res', res.data.data)
                    if (res.data.data) {
                        this.props.onSubmitChartData({...data, name: res.data.data.name}, res.data.data._id);
                        this.props.resetActiveTab();
                        showNotification('success', 'Report created successfuly' )
                    } else {
                        showNotification('error', res.data.message )
                    }
                })
            }
        } else {
            this.setState({chartNotSelected: true})
        }
    }

    onChangeRadioBtn = (e, radioType) => {
        let {data} = this.state;
        data = {...data}
        if(radioType === 'evaluate'){
            data[radioType] = e.target.value;
        }else{
            data.yAxis.valueType = e.target.value;
        }
        this.setState({data})
    }

    handleReportName = (e) =>{
        this.setState({reportName:e.target.value})
    }

    render() {
        let {templateList,fieldList, data,chartNotSelected,fetchingFields, reportName, processFields} = this.state;

        let selectedProcess,
            selectedField,
            xAxisSelectedFields,
            yAxisSelectedFields,
            selectedChart,
            selectedAggregateFields,
            xAxisLabel, yAxisLabel, valueType, evaluate;

        let defaultDropdownValue = [];

        if(data){
            selectedProcess = data.process ? data.process : defaultDropdownValue;
            selectedField = data.form ? data.form : defaultDropdownValue;
            selectedChart = data.chart ? data.chart : defaultDropdownValue;
            xAxisSelectedFields = data.xAxis.fields ? data.xAxis.fields : defaultDropdownValue;
            yAxisSelectedFields = data.yAxis.fields ? data.yAxis.fields : defaultDropdownValue;
            selectedAggregateFields = data.yAxis.aggregate.length > 0 ? data.yAxis.aggregate : '';
            xAxisLabel = data.xAxis.label ? data.xAxis.label : '';
            yAxisLabel = data.yAxis.label ? data.yAxis.label : '';
            valueType = data.yAxis.valueType ? data.yAxis.valueType : '';
            evaluate = data.evaluate ? data.evaluate : '';
        }

        // console.log('data', fieldList)

        return (
            <React.Fragment>
                <Row style={query} type='flex' justify='space-between'>
                    Switch to query mode
                    <Icon type="right-circle" style={{fontSize:'20px'}}/>
                </Row>
                <Row style={mb}>
                    <label className='f15' style={{color:'#8697A8'}}>Report Name</label>
                    <Input placeholder='Enter report name' className='custom-input' value={reportName} style={{marginTop:'12px'}} size='large' allowClear onChange={this.handleReportName}/>
                </Row>
                <ProcessList onSelectProcess={this.onSelectProcess} selectedProcess={selectedProcess}  rowStyle={mb}/>
                {/*<ProcessField onSelectField={this.onSelectField} processFields={processFields} selectedField={selectedField} rowStyle={mb}/>*/}
                <ChartList chartList={chartList} chartNotSelected={chartNotSelected} onSelectChart={this.onSelectChart} selectedChart={selectedChart} rowStyle={mb}/>
                {
                    selectedChart.key &&
                        <React.Fragment>
                            <XaxisSection fetchingFields={fetchingFields} fieldList={processFields} xAxisLabel={xAxisLabel} onChangeLabel={this.onChangeLabel} selectedFields={xAxisSelectedFields} onSelectFields={this.onSelectFieldsForXaxis} rowStyle={{...mb, ...axisContainer}}/>
                            <YaxisSection fetchingFields={fetchingFields} fieldList={processFields} yAxisLabel={yAxisLabel} onChangeLabel={this.onChangeLabel} selectedFields={yAxisSelectedFields} onSelectFields={this.onSelectFieldsForYaxis} rowStyle={{...mb, ...axisContainer}}
                                          valueType={valueType} selectedAggregateFields={selectedAggregateFields} onSelectAggregateOption={this.onSelectAggregateOption} onChangeRadioBtn={this.onChangeRadioBtn}/>
                            <EvaluationSection onChangeRadioBtn={this.onChangeRadioBtn} value={evaluate}/>
                        </React.Fragment>
                }

                <Row type='flex' justify='end' style={{marginTop:'50px'}}>
                    <Button onClick={this.props.resetActiveTab} style={{marginRight: '16px', width: '97px'}}>Cancel</Button>
                    <Button type='primary' style={{width: '97px'}} onClick={this.onSubmitData}>Done</Button>
                </Row>
            </React.Fragment>
        )
    }
}
