import React, { Component } from 'react'
import RGL, { WidthProvider } from "react-grid-layout";
import {Input, InputNumber, Select, DatePicker, Icon, Upload, Button} from 'antd'

const ReactGridLayout = WidthProvider(RGL);

export default class GridLayoutFormBuilder extends Component {

    state = {
        activeId : null
    }

    onLayoutChange = (layout) => {
        this.props.onLayoutChange(layout)
    }

    getFieldData = (e, type, field) => {
        e.preventDefault()
        let el  = document.getElementsByClassName('app-content-block');
        let top = type === 'textarea' ? 43 : 83
        let pos = {
            top  : (e.target.getBoundingClientRect().top+el[0].scrollTop) - top,
            left : e.clientX - (field.dataType === 'number' ? 450 : 600)
        }
        this.setState({activeId:field.id})
        this.props.togglePropertiesModal(pos, true, field)
    }

    render() {
        let {layout, allFields, deleteField} = this.props;
        // console.log('All Fields', allFields)
        return (
            <ReactGridLayout
                rowHeight		= {10}
                cols			= {24}
                // onResize		= {e=>this.onLayoutChange(e)}
                width			= {10}
                layout			= {layout}
                onLayoutChange	= {e=>this.onLayoutChange(e)}
                draggableHandle	= ".gridDragHandler"
                draggableCancel	= ".MyDragCancel"
            >
                {
                    allFields.map(field=>{
                        switch(field.dataType){
                            case 'url' :
                            case 'telephone' :
                            case 'email' :
                            case 'text' :
                                return (
                                    <div className={field.id === this.state.activeId ? 'item gridDragHandler active' : 'item gridDragHandler'} key={field.id}>
                                        <span className='edit'>
                                            <Icon type='edit' onClick={e=>this.getFieldData(e, field.dataType, field)} className='mr-10' />
                                            <Icon type='delete' onClick={e=>deleteField(field.id)} />
                                        </span>
                                        <label>
                                            {field.alias_name ? field.alias_name : field.name}
                                            {
                                                field.mandatory &&
                                                    <sup>*</sup>
                                            }
                                        </label>
                                        <Input size='large'  className='custom-input' allowClear placeholder={field.alias_help_text ? field.alias_help_text : field.helpText} />
                                    </div>
                                )
                            case 'number' :
                                    return (
                                        <div className={field.id === this.state.activeId ? 'item gridDragHandler active' : 'item gridDragHandler'} key={field.id}>
                                            <span className='edit'>
                                                <Icon type='edit' onClick={e=>this.getFieldData(e, field.dataType, field)} className='mr-10' />
                                                <Icon type='delete' onClick={e=>deleteField(field.id)} />
                                            </span>
                                            <label>
                                                {field.alias_name ? field.alias_name : field.name}
                                                {
                                                    field.mandatory &&
                                                        <sup>*</sup>
                                                }
                                            </label>
                                            <br/>
                                            <InputNumber size='large'  className='custom-input fw' allowClear placeholder={field.alias_help_text ? field.alias_help_text : field.helpText} />
                                        </div>
                                    )
                            case 'dropdown' :
                                return (
                                    <div className={field.id === this.state.activeId ? 'item gridDragHandler active' : 'item gridDragHandler'} key={field.id}>
                                        <span className='edit'>
                                            <Icon type='edit' onClick={e=>this.getFieldData(e, field.dataType, field)} className='mr-10' />
                                            <Icon type='delete' onClick={e=>deleteField(field.id)} />
                                        </span>
                                       <label>
                                            {field.alias_name ? field.alias_name : field.name}
                                            {
                                                field.mandatory &&
                                                    <sup>*</sup>
                                            }
                                        </label>
                                        <Select
                                            // getPopupContainer={(e)=>e.parentNode}
                                            className='custom-select fw'
                                            value = {undefined}
                                            size='large'
                                            allowClear
                                            placeholder={field.alias_help_text ? field.alias_help_text : field.helpText}                                            
                                        >
                                            {
                                                field.options.map(opt => (
                                                    <Select.Option disabled key={opt}>{opt}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </div>
                                )
                            case 'textarea' :
                                return (
                                    <div className={field.id === this.state.activeId ? 'item gridDragHandler active' : 'item gridDragHandler'} key={field.id}>
                                        <span className='edit'>
                                            <Icon type='edit' onClick={e=>this.getFieldData(e, field.dataType, field)} className='mr-10' />
                                            <Icon type='delete' onClick={e=>deleteField(field.id)} />
                                        </span>
                                       <label>
                                            {field.alias_name ? field.alias_name : field.name}
                                            {
                                                field.mandatory &&
                                                    <sup>*</sup>
                                            }
                                        </label>
                                        <Input.TextArea
                                            placeholder={field.alias_help_text ? field.alias_help_text : field.helpText}
                                            autosize={{ minRows: 3, maxRows: 6 }}
                                            className='custom-input'                                            
                                        />
                                    </div>
                                )
                            case 'date' :
                                return (
                                    <div className={field.id === this.state.activeId ? 'item gridDragHandler active' : 'item gridDragHandler'} key={field.id}>
                                        <span className='edit'>
                                            <Icon type='edit' onClick={e=>this.getFieldData(e, field.dataType, field)} className='mr-10' />
                                            <Icon type='delete' onClick={e=>deleteField(field.id)} />
                                        </span>
                                       <label>
                                            {field.alias_name ? field.alias_name : field.name}
                                            {
                                                field.mandatory &&
                                                    <sup>*</sup>
                                            }
                                        </label>
                                        <br/>
                                        <DatePicker 
                                            size='large' 
                                            placeholder={field.alias_help_text ? field.alias_help_text : field.helpText}
                                            className = 'fw custom-input'                                            
                                        />
                                    </div>
                                )

                            case 'file' :
                                return (
                                    <div className={field.id === this.state.activeId ? 'item gridDragHandler active' : 'item gridDragHandler'} key={field.id}>
                                        <span className='edit'>
                                            <Icon type='edit' onClick={e=>this.getFieldData(e, field.dataType, field)} className='mr-10' />
                                            <Icon type='delete' onClick={e=>deleteField(field.id)} />
                                        </span>
                                       <label>
                                            {field.alias_name ? field.alias_name : field.name}
                                            {
                                                field.mandatory &&
                                                    <sup>*</sup>
                                            }
                                        </label>
                                        <br/>
                                        <Upload>
                                            <Button size='large'>
                                                <Icon type="upload" /> {field.alias_help_text ? field.alias_help_text : field.helpText}
                                            </Button>
                                        </Upload>
                                    </div>
                                )
                            
                            default: break;
                        }
                    })
                }
            </ReactGridLayout>
        )
    }
}
