import React, { Component } from 'react'
import {Row} from 'antd'
import FormSections from './formSections';

export default class FormFields extends Component {
    render() {
        let {fields} = this.props
        return (
            <Row>
                {
                    this.props.sections.map(section => {
                        // let sectionFields = fields.filter(f=>f.section_id === section.section_id)
                        let sectionFields = []
                        Object.keys(fields).map(f=>{
                            if(fields[f].section_id === section.section_id){
                                sectionFields.push(fields[f])
                            }
                        })
                        return(
                            <FormSections
                                key           = {section.section_id}
                                section       = {section}
                                sectionFields = {sectionFields}
                                activeTask    = {this.props.activeDataset}
                                updateTask    = {this.props.updateRecordFields}
                            />
                        )
                    })
                }
                <FormSections />
            </Row>
        )
    }
}