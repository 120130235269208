import React from 'react';
import '../App.css';
import 'antd/dist/antd.css';
import AppLogo from '../utils/AppLogo'
import { Redirect } from 'react-router-dom'
import Navigation from "../navigation/Navigations";
import PageNavigation from "../navigation/pageNavigation";
import AppRouters from "./AppRouters";
import PageRouters from "./PageRouters";
import { Layout, Row, Button, Dropdown, Icon, Menu, Spin} from 'antd';
import {connect} from 'react-redux';
import {socket,initializeSocket} from "../config/SocketInit";
import {teamSocket, userSocket, recordSocket, datasetSocket} from "./utils/Sockets";
import MyProfileDropdown from '../utils/myProfileDropdown'
import {defaultConfig} from '../config/Axios';
import {fetchCurrentUserRequest} from "../profile/duck/ProfileActions";
import {fetchTeamsRequest} from "../teams/duck/TeamsActions";
import {fetchUsersRequest} from "../people/duck/UserActions";
import {fetchPagesRequest} from '../pageBuilder/duck/PageActions'
import {fetchFieldsRequest} from '../fields/duck/FieldActions'
import {fetchOrganisationsRequest} from '../organisation/duck/OrganisationActions'
import {fetchTagsRequest} from '../tags/duck/TagsActions'
import {fetchProcessRequest} from '../process/duck/ProcessActions'
import {fetchNavigationProjects} from '../project/duck/ProjectActions'
import ProjectCreatorContainer from '../project/ProjectCreatorContainer';
import DataSetCreatorContainer from '../dataset/DatasetCreatorContainer';
import FieldCreatorContainer from '../fields/fieldCreatorContainer';
import TagCreatorContainer from '../tags/TagCreatorContainer';
import ProcessCreatorContainer from '../processBuilder/ProcessCreatorContainer';
// import MasterCreatorContainer from '../master/MasterCreatorContainer'

class AppLayout extends React.Component {

    state = {
        collapsed   : false
    }

    constructor(props) {
        super(props);
        defaultConfig.setRequests();
        this.initializeApp()
        // window.addEventListener('online', this.appConnected);
        // window.addEventListener('offline', this.setConnectivity);
    }

    componentWillUnmount() {
        // window.removeEventListener('online', this.appConnected);
    }

    initializeApp = () => {
        initializeSocket();
        this.setupSockets();
        this.setupAPIs();
    }

    setupAPIs = () => {
        fetchCurrentUserRequest();
        fetchTeamsRequest();
        fetchUsersRequest({limit:20,offset:0});
        fetchFieldsRequest({limit:500,offset:0});
        fetchPagesRequest();
        fetchProcessRequest({limit:100,offset:0});
        fetchTagsRequest({limit:100,offset:0});
        fetchNavigationProjects({limit:30,offset:0})
        fetchOrganisationsRequest();
    }

    setupSockets = () => {
        userSocket();
        teamSocket();
        recordSocket();
        // datasetSocket();
    }

    componentDidMount(){
        this.props.fetchNavigationProjects();
    }


    setRedirect = () => {
        if(this.props.activePage.activePage && this.props.activePage.activePage.id){
            let url = '/app/pages/creator/' + this.props.activePage.activePage.id;
            return <Redirect to={url} />
        }
    }

    toggle = () => {
        this.setState({
            collapsed : !this.state.collapsed,
        });
    }

    menu = (
        <Menu>
            <Menu.Item>
                <ProjectCreatorContainer />
            </Menu.Item>
            <Menu.Item>
                <ProcessCreatorContainer />
            </Menu.Item>
            <Menu.Item>
                <FieldCreatorContainer />
            </Menu.Item>
            <Menu.Item>
                <TagCreatorContainer />
            </Menu.Item>
            <Menu.Item>
                <DataSetCreatorContainer />
            </Menu.Item>
        </Menu>
    );

    render(){
        let {currentUser}   = this.props;
        const header        = {background: '#fff', padding: '0px 25px 0 10px',borderBottom:'1px solid #D8D8D8'};
        const sider         = {background:'#F8FAFB',overflow:'hidden auto'}
        // const toggleBtn     = {fontSize:'22px',verticalAlign:'middle',marginLeft:'15px'};
        if (!currentUser) {
            return <Row type="flex" justify="center" align="middle" style={{paddingTop: '20%'}}>
                        <Spin size="large" tip='Loading...' />
                    </Row>
        }
        return (
            <Layout className='fh'>
                <Layout.Header style={header}>
                    <Row className='pull-left' style={{display:'flex',paddingTop:'6px'}}>
                        <AppLogo />
                    </Row>
                    {/* <Row className='pull-left'>
                        <Icon
                            style   = {toggleBtn}
                            type    = {this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                            onClick = {this.toggle}
                        />
                    </Row> */}
                    <Row className='pull-right'>
                        <Dropdown overlay={this.menu} trigger={["click"]}>
                            <Button type='primary' className='gradient-bg'>
                                <Icon type='plus' />
                                <span>Create</span>
                            </Button>
                        </Dropdown>
                        <MyProfileDropdown/>
                    </Row>
                </Layout.Header>
                {
                    this.props.activePage.activePage && this.props.activePage.activePage.id
                    ?
                        <Layout>
                            {this.setRedirect()}
                            <Layout.Sider collapsedWidth={this.state.collapsed ? '0' : '220'} width='220' trigger={null} theme='light' collapsible collapsed={this.state.collapsed} style={sider}>
                                <PageNavigation/>
                            </Layout.Sider>
                            <Layout.Content className='app-content-block'>
                                <PageRouters />
                            </Layout.Content>
                        </Layout>
                    :
                        <Layout>
                            <Layout.Sider collapsedWidth={this.state.collapsed ? '0' : '220'} width='220' trigger={null} theme='light' collapsible collapsed={this.state.collapsed} style={sider}>
                                <Navigation/>
                            </Layout.Sider>
                            <Layout.Content className='app-content-block'>
                                <AppRouters />
                            </Layout.Content>
                        </Layout>
                }
            </Layout>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        currentUser : state.currentUser && state.currentUser.data && state.currentUser.data.data,
        activePage  : state.activePage
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchNavigationProjects: () => dispatch(fetchNavigationProjects())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
