import React from 'react'
import {Row, Icon, Alert} from 'antd'
import { useDrag } from 'react-dnd'

export default function FieldTypeList(props) {
    let {fields, searchText} = props;
    fields = fields.filter(f=>f.attributes.name.toLowerCase().includes(searchText.toLowerCase()))
    return (
        <Row className='fieldListColumn'>
            {
                fields.length > 0
                ?
                    fields.map(f=>{
                        return <Field key={f.id} name={f.attributes.name} type={f.attributes.type} icon={chooseIcon(f.attributes.type)} id={f.id} helpText={f.attributes.help_text} options={f.attributes.options} />
                    })
                :
                    <Alert message="No field found..." type="info" />
            }
        </Row>
    )
}

const chooseIcon = (type) => {
    switch(type){
        case 'text' : return 'font-colors';
        case 'email' : return 'mail';
        case 'number' : return 'number';
        case 'telephone' : return 'phone';
        case 'dropdown' : return 'down-circle';
        case 'textarea' : return 'align-left';
        case 'date' : return 'calendar';
        case 'url' : return 'global';
        case 'file' : return 'cloud-upload';
        default : break;
    }

}

const Field = ({ name, type, icon, id, helpText, options }) => {
    const [{ isDragging }, drag] = useDrag({
        item: { name, type:'field', id, dataType: type, helpText, options },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult()
            if (item && dropResult) {
                // alert(`You dropped ${item.name} into Section:  ${dropResult.name}`)
            }
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })
    const opacity = isDragging ? 0.4 : 1

    

    return (
        <div ref={drag} style={{opacity}} className='items'>
            <Icon type={icon} />
            <span>{name}</span>
        </div>
    )
}