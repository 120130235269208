import React, { Component } from 'react'
import {Row, Radio, Select, Col} from 'antd'
import TeamSelect from '../../../utils/TeamSelect'
import PeopleSelect from '../../../utils/PeopleSelect'

const radioStyle = {
    display: 'block',
    marginLeft:'5px',
    marginBottom :'10px',
    color:'#697789'
};

export default class Assignment extends Component {
    
    state= {
        value           : this.props.activeStep.assignment.method === 'manual' ? this.props.activeStep.assignment.method : 'auto',
        subValue        : this.props.activeStep.assignment.method !== 'manual' ? this.props.activeStep.assignment.method : 'freest',
        selectedUsers   : this.props.activeStep.assignment.people,
        selectedTeams   : this.props.activeStep.assignment.teams
    }

    onChange = e => {
        // console.log('radio checked', e.target.value);
        let value = e.target.value
        if(value === 'manual'){
            this.setState({value});
        }else{
            this.setState({value:'auto', subValue:value === 'auto' ? 'freest' : value});
        }
        
        let steps   = Object.assign({},this.props.steps)
        steps[this.props.activeStep.id].assignment.method = value === 'manual' ? 'manual' : value === 'auto' ? 'freest' : value
        if(value === 'manual'){
            steps[this.props.activeStep.id].assignment.people = []
            steps[this.props.activeStep.id].assignment.teams  = []
        }
        this.props.updateSteps(steps)
    };

    selectUsers = (val) => {
        this.setState({selectedUsers:val})
        let steps   = Object.assign({},this.props.steps)
        steps[this.props.activeStep.id].assignment.people = val
        this.props.updateSteps(steps)
    }

    selectTeam = (val) => {
        this.setState({selectedTeams:val})
        let steps   = Object.assign({},this.props.steps)
        steps[this.props.activeStep.id].assignment.teams = val
        this.props.updateSteps(steps)
    }

    render() {
        return (
            <>
                <Row style={{fontSize:'30px',color:'#44566C',fontWeight:'500'}} className='mt-20 mb-20'>
                    Whom will this task be assigned to?
                </Row>
                <Row>
                    <Radio.Group onChange={this.onChange} value={this.state.value}>
                        <Radio style={{...radioStyle,marginBottom:'40px'}} className='mb-20' value={'manual'}>
                            <span>
                                Manual Assignment
                            </span>
                            {/* {
                                this.state.value === 'manual' &&
                                <Row>
                                    <Select
                                        getPopupContainer={(e)=>e.parentNode}
                                        allowClear
                                        showSearch
                                        className   = 'fw custom-select mt-20 ml-20'
                                        size        = 'large'
                                        placeholder = 'Select assignees'
                                        defaultValue= {this.state.selectedUsers}
                                        onChange    = {this.selectUsers}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children[1].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            this.props.users.map((user, index) => {
                                                return <Select.Option key={index} value={user.id}>
                                                            <Avatar size="small" style={{backgroundColor: getAvatarColor(user.id)}}>
                                                                {getNameInitials(user.attributes.name ? user.attributes.name : '')}
                                                            </Avatar>
                                                            <span className='ml-10'>{user.attributes.name}</span>
                                                        </Select.Option>
                                            })
                                        }
                                    </Select>
                                </Row>
                            } */}
                        </Radio>
                        <Radio style={radioStyle} value={'auto'}>
                            <span>
                                Auto Assign
                            </span>
                        </Radio>
                    </Radio.Group>
                    {
                        this.state.value === 'auto' &&
                            <>
                                <Row className='mb-10' style={{textShadow:'0 0',color:'#56667A',marginLeft:'29px'}}>
                                    Select team or people within which auto assignment has to happen
                                </Row>
                                <Row style={{marginLeft:'29px'}} className='mb-10'>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <TeamSelect
                                                mode            = 'multiple'
                                                placeholder     = 'Select team'
                                                onChange        = {this.selectTeam}
                                                defaultValue    = {this.state.selectedTeams}
                                            />
                                            
                                        </Col>
                                        <Col span={12}>
                                            <PeopleSelect
                                                mode            = 'multiple'
                                                placeholder     = 'Select people'
                                                onChange        = {this.selectUsers}
                                                defaultValue    = {this.state.selectedUsers}
                                            />
                                        </Col>
                                    </Row>
                                </Row>
                                <Row  style={{marginLeft:'29px'}}>
                                    <Row className='mb-10' style={{textShadow:'0 0',color:'#56667A'}}>
                                        Select auto-assignment logic
                                    </Row>
                                    <Radio.Group className='fw' value={this.state.subValue} onChange={this.onChange}>
                                        <Radio style={radioStyle} value={'freest'}>
                                            <span>
                                                Freest
                                            </span>
                                            <Row style={{color:'#B4BBC4',marginLeft:'23px'}} className='mt-5'>
                                                This step is assigned to the person with the least number of pending items.
                                            </Row>
                                        </Radio>
                                        <Radio style={radioStyle} value={'roundrobin'}>
                                            <span>
                                                Round Robin
                                            </span>
                                            <Row style={{color:'#B4BBC4',marginLeft:'23px'}} className='mt-5'>
                                                This step is assigned to the next person in rotation.
                                            </Row>
                                        </Radio>
                                        {/* <Radio style={radioStyle} value='previousdoer'>
                                            <span>
                                                Doer of previous task assigns this task.
                                            </span>
                                            <Row style={{color:'#B4BBC4',marginLeft:'23px'}} className='mt-5'>
                                                This step is assigned to the doer of previous task.
                                            </Row>
                                        </Radio> */}
                                    </Radio.Group>
                                </Row>
                            </>
                    }
                </Row>
            </>
        )
    }
}
