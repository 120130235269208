import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Typography, Divider } from 'antd';
const { Title } = Typography;

class WorkflowContainer extends Component {
    render() {
        return (
            <Typography>
                <Title level={2}>Hi I am Workflows ....</Title>
            </Typography>
        );
    }
}

WorkflowContainer.propTypes = {};

export default WorkflowContainer;
