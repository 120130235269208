import React from 'react';
import { Row, Card, Skeleton, Button, Tag } from 'antd';
import { Link } from 'react-router-dom'

import './styles.css';

class YourProjectsList extends React.Component {

  renderLoadingCards = () => {
    return <Row>
      {[1, 2, 3, 4, 5 ,6, 7].map((i, index) => <div className="projectlist-item-container" key={index}>
        <Card className="projectlist-item-card">
          <Skeleton active loading={true} title={null} paragraph={{rows: 4}}>
          </Skeleton>
        </Card>
        <div className="projectlist-item-shadow"></div>
      </div>)}
    </Row>
  }

  renderProject = (project, index) => {
    return (
      <div className="projectlist-item-container" key={index}>
        <Card className="projectlist-item-card" bodyStyle={{height: "100%", display: "flex", flexDirection: "column", padding: 0}}>
          <div className="project-draft">
            {project.draft && <Tag color="rgb(255, 99, 120)">Draft</Tag>}
          </div>
          <div className="project-name">{project.name}</div>
          <div className="project-action">
            <Button style={{width: "100%"}} type="primary">Edit Process</Button>
          </div>
        </Card>
        <div className="projectlist-item-shadow"></div>
      </div>
    );
  }

  renderProjects = () => {
    const { projects = [] } = this.props;
    return (
        <Row>
          {projects.map((project, index) => (
            <Link to={`/app/projects/${project._id}`} key={index}>
              {this.renderProject(project, index)}
            </Link>
          ))}
        </Row>
    );
  }

  render() {
    const { loading, projects } = this.props;
    return (
      <>
        {loading && this.renderLoadingCards()}
        {!loading && this.renderProjects()}
      </>
    );
  }
}

export default YourProjectsList;
