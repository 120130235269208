import React, { Component } from 'react';
import COLORS from './colors';
import {
	PieChart, Pie, Cell, ResponsiveContainer, Legend
} from 'recharts';
// const data02 = [
// 	{ name: 'Group A', value: 400 }, { name: 'Group B', value: 300 },
// 	{ name: 'Group C', value: 300 }, { name: 'Group D', value: 200 },
//   ];
const defaultData = [
	{ name: 'Group A', value: 400 },
	{ name: 'Group B', value: 300 },
	{ name: 'Group C', value: 300 },
	{ name: 'Group D', value: 200 },
];

const RADIAN = Math.PI / 180;

export default class PieChartType extends Component {

	render() {
		let data = this.props.data
		let barDataKy = [];
		let graphData = [];

		if(data.length > 0) {
			for (let j = 0; j < data.length; j++) {
				for (let i = 0; i < data[j].yAxis.data.length; i++) {
					graphData.push({
						name:data[j].xAxis.data[0].value,
						value:+data[j].yAxis.data[0].value
					})
				}
			}
		}else{
			graphData = defaultData;
		}

		const renderCustomizedLabel = ({
										   cx, cy, midAngle, innerRadius, outerRadius, percent, index,name,value
									   }) => {
			const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
			const x = cx + radius * Math.cos(-midAngle * RADIAN);
			const y = cy + radius * Math.sin(-midAngle * RADIAN);

			return (
				<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
					{`${name} ${value}`}
				</text>
			);
		};
		// console.log('graphData', graphData, data)

		return (
			<ResponsiveContainer>
				{/*<PieChart>*/}
				{/*    /!* <Pie data={data01} dataKey="value"  fill="#8884d8" /> *!/*/}
				{/*    <Pie data={data02} dataKey="value"   fill="#8884d8" label />*/}
				{/*    <Legend />*/}
				{/*</PieChart>*/}

				<PieChart>
					<Pie
						data={graphData}
						labelLine={false}
						label={renderCustomizedLabel}
						fill="#8884d8"
						dataKey="value"
					>
						{
							graphData.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]} />)
						}
					</Pie>
					<Legend />
				</PieChart>
			</ResponsiveContainer>
		)
	}
}
