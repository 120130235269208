import React from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {Menu, Row, Spin, Icon, Empty, Col, Select, Input, Card, Modal, Tag, Skeleton} from 'antd';
import {deleteDataset, fetchDatasets} from './duck/DatasetActions';
import moment from 'moment';

const {confirm} = Modal;

class DatasetListContainer extends React.Component {

    state = {
        loading: false,
        activeBO: null,
        searchText: '',
        sortBy: [],
    };

    componentDidMount() {
        this.setState({loading: true})
        if (!(this.props.dataset && this.props.dataset.dataset)) {
            this.props.fetchDatasets()
        }
    }

    componentDidUpdate(prevProps) {
        if (this.state.loading && this.props.dataset.dataset) {
            this.setState({
                loading: false
            });
        }
    }

    renderLoadingCards = () => {
        return [1, 2, 3, 4, 5, 6, 7].map((i, index) =>
            <div className="marketplacelist-item-container" key={index}>
                <Card className="marketplacelist-item-card">
                    <Skeleton active loading={true} title={null} paragraph={{rows: 4}}>
                    </Skeleton>
                </Card>
                <div className="marketplacelist-item-shadow"></div>
            </div>
        );
    };

    deleteDataset = (id) => {
        confirm({
            title: 'Do you Want to delete this dataset?',
            content: '',
            okText: 'Yes',
            onOk: () => {
                console.log(this.props)
                this.props.deleteDatasetID(id);
                this.setState({activeBO: null})
            },
            onCancel: () => {
                console.log('Cancel');
            }
        });
    }

    editProject = (datasetId) => {
        this.selectProject(datasetId)
        console.log('edit called', datasetId)
    }

    selectProject = (bo) => {
        this.setState({activeBO: bo})
    }

    sort = (val) => {
        this.setState({sortBy: val})
    }

    searchProject = (e) => {
        this.setState({searchText: e.target.value})
    }

    render() {
        if (this.state.activeBO) {
            let url = `/app/dataset/${this.state.activeBO.id}`;
            return <Redirect to={{pathname: url, state: {redirect: true}}} push/>
        }
        const {loading} = this.state;
        if (loading) {
            return this.renderLoadingCards();
        }
        // console.log('Dataset: ',this.props.dataset)
        let {dataset = []} = this.props.dataset;

        dataset = dataset.data;
        // console.log(dataset)
        if (this.state.searchText.trim() !== '') {
            dataset = dataset && dataset.filter(f => f.attributes.name.toLowerCase().includes(this.state.searchText.toLowerCase()))
        }
        if (this.state.sortBy === 'alphabetical') {
            let tg = [...dataset]
            dataset = tg.sort((a, b) => a.attributes.name.localeCompare(b.attributes.name))
        } else if (this.state.sortBy === 'creation') {
            let tg = [...dataset];
            dataset = tg.reverse()
        }
        return (
            <Row>
                <Spin spinning={false} size='large' style={{minHeight: '60vh'}}>
                    <Row gutter={16} type='flex' justify='end' style={{marginBottom: '40px'}}>
                        <Col span={4}>
                            <Select
                                getPopupContainer={(e) => e.parentNode}
                                style={{boxShadow: '0px 8px 16px rgba(169, 194, 209, 0.1)'}}
                                className='fw custom-select white'
                                size='large'
                                placeholder='Sort by'
                                defaultValue={this.state.sortBy}
                                onChange={this.sort}
                                allowClear

                            >
                                <Select.Option value='alphabetical'>
                                    <Icon type="sort-ascending" className='mr-10' style={{fontSize: '18px'}}/>
                                    Alphabetical
                                </Select.Option>
                                <Select.Option value='creation'>
                                    <Icon type="calendar" className='mr-10'/>
                                    Creation Date
                                </Select.Option>
                            </Select>
                        </Col>
                        <Col span={6}>
                            <Input
                                placeholder="Search dataset"
                                prefix={<Icon type="search" style={{fontSize: '20px', color: 'rgba(0,0,0,.25)'}}/>}
                                size='large'
                                className='input-box searchbox'
                                onKeyUp={this.searchProject}
                            />
                        </Col>
                    </Row>
                </Spin>
                <Row type={'flex'} justify={'start'} align={'middle'}>
                    {
                        dataset && dataset.length > 0
                            ?
                            dataset.map((datasetObject, index) => (
                                <Card
                                    bordered={false}
                                    hoverable
                                    className={'custom-card'}
                                    key={datasetObject.id}
                                    onClick={() => this.selectProject(datasetObject)}
                                >
                                    <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
                                        {datasetObject.attributes.draft && <Row>
                                            <Tag color='#FF6378'>Draft</Tag>
                                        </Row>}
                                        <Row type="flex" style={{flexGrow: 1}}>
                                            <span style={{
                                                fontSize: '24px',
                                                lineHeight: '32px',
                                                fontWeight: 'bold',
                                                alignItems: 'center',
                                                display: 'flex'
                                            }}>{datasetObject.attributes.name}</span>
                                        </Row>
                                        <Row>
                                            <span
                                                style={{fontSize: '12px'}}>Last edited {moment(datasetObject.attributes.modified_at).fromNow()}</span>
                                        </Row></div>
                                </Card>
                            ))
                            :
                            <Row style={{minHeight: '50vh', width: '100%'}} type='flex' align='middle' justify='center'>
                                <Empty description={false}/>
                            </Row>
                    }

                </Row>
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        dataset: state.dataset
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteDatasetID: (id) => dispatch(deleteDataset(id)),
        fetchDatasets: () => dispatch(fetchDatasets())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DatasetListContainer);
