import React, {Component} from 'react';
import {Row, Select} from "antd";
import {connect} from 'react-redux';
import axios from 'axios';
import appUrl from "../../constants/AppUrl";

class ProcessList extends Component {

    state = {
        processList : []
    }

    componentDidMount() {
        this.setState({loading:true})
        axios({
            url: appUrl.PROJECTS_URL,
            method:'get'
        }).then(res=>{
            console.log('ProjectList: ',res.data.data)
            this.setState({processList:res.data.data, loading:false})
        })
    }

    onSelect = (e) => {
        // console.log('e', e);
        let selectedPs = this.state.processList.find(ps=>ps.id === e.key)
        this.props.onSelectProcess(selectedPs)
    }

    render() {
        let {processList,loading}      = this.state;
        let {selectedProcess}  = this.props;

        let placeHolder = null;

        if(loading){
            placeHolder = 'Fetching projects...'
        }else{
            placeHolder = selectedProcess ? 'Select Projects' : 'Projects are empty'
        }

        processList = processList.map(process=>{
            if(process.attributes.fields){
                return  <Select.Option key={process.id} value={process.id}>{process.attributes.name}</Select.Option>
            }
        });
        return (
            <Row style={this.props.rowStyle}>
                <label className='f15' style={{color:'#8697A8'}}>Project</label>
                <Select 
                    getPopupContainer={(e)=>e.parentNode}
                    className='fw custom-select'
                    placeholder={placeHolder}
                    loading = {loading}
                    value   = {selectedProcess}
                    labelInValue 
                    size    = 'large' 
                    style   = {{marginTop:'12px'}}
                    onSelect= {this.onSelect}>
                    {processList}
                </Select>
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        state
    }
}

export default connect(mapStateToProps)(ProcessList);
