import { put, takeLatest, takeLeading, fork, call } from 'redux-saga/effects';
import { message } from 'antd';
import axios from 'axios';
import appUrl from '../../constants/AppUrl';
import TYPES from './MarketplaceAppActionTypes';
import {
  fetchMarketplaceAppSuccess,
  createMarketplaceAppSuccess,
  updateMarketplaceAppSuccess,
  updateMarketplaceAppFailed,
  installAppFailed,
  installAppSuccess,
} from './MarketplaceAppActions';

const createAppRequest = (data) => {
  return axios({
    method: 'POST',
    url: appUrl.MARKETPLACE_URL,
    data
  })
}

function* createApp(action) {
  try {
    const response = yield call(createAppRequest, action.app);
    if (!response.data.meta || response.data.meta.status !== 200) {
      message.error('Error creating app, please refresh to try again!');
    } else {
      const app = response.data.data;
      yield put(fetchMarketplaceAppSuccess({[app._id]: app}));
      yield put(createMarketplaceAppSuccess(app._id));
    }
  } catch(error) {
    console.log(error);
    message.error('Error creating app, please refresh to try again!');
  }
}

const fetchMarketplaceAppRequest = (id) => {
  return axios({
    method: 'GET',
    url: `${appUrl.MARKETPLACE_URL}/${id}`,
  })
}

function* fetchMarketplaceApp(action) {
  try {
    const response = yield call(fetchMarketplaceAppRequest, action.id);
    if (!response.data.meta || response.data.meta.status !== 200) {
      message.error('Error fetching app.');
    } else {
      const app = response.data.data;
      yield put(fetchMarketplaceAppSuccess({[app._id]: app}));
    }
  } catch(error) {
    message.error('Error fetching app.');
    console.log(error);
  }
}

const updateMarketplaceAppRequest = (id, data) => {
  return axios({
    method: 'PUT',
    url: `${appUrl.MARKETPLACE_URL}/${id}`,
    data
  });
}

function* updateMarketplaceApp(action) {
  try {
    const response = yield call(updateMarketplaceAppRequest, action.app.data._id, action.app);
    if (!response.data.meta || response.data.meta.status !== 200) {
      yield put(updateMarketplaceAppFailed(action.app.data._id))
    } else {
      const app = response.data.data;
      yield put(fetchMarketplaceAppSuccess({[app._id]: app}));
      yield put(updateMarketplaceAppSuccess(app._id))
    }
  } catch(error) {
    yield put(updateMarketplaceAppFailed(action.app.data._id))
    console.log(error);
  }
}

const installAppRequest = (data) => {
  return axios({
    method: 'POST',
    url: appUrl.MARKETPLACE_INSTALL_URL,
    data
  });
}

function* installApp(action) {
  try {
    const response = yield call(installAppRequest, action.app);
    if (!response.data.meta || response.data.meta.status !== 200) {
      yield put(installAppFailed(action.app.data.app_id));
    } else {
      const app = response.data.data;
      yield put(installAppSuccess(action.app.data.app_id))
    }
  } catch(error) {
    yield put(installAppFailed(action.app.data.app_id));
    console.log(error);
  }
}

function* watchApp() {
  yield takeLeading(TYPES.CREATE_MARKETPLACE_APP, createApp);
  yield takeLeading(TYPES.INSTALL_APP, installApp);
  yield takeLeading(TYPES.FETCH_MARKETPLACE_APP, fetchMarketplaceApp);
  yield takeLeading(TYPES.UPDATE_MARLETPLACCE_APP, updateMarketplaceApp);
}

export default function* appSagas() {
  yield fork(watchApp);
}
