import React, { Component } from 'react'
import {Row, Col, Select, Input, Icon, Divider, Typography} from 'antd'

export default class FieldTypes extends Component {

    handleName = (e) =>{
        this.props.handleName(e.target.value)
    }
    
    handleHelpText = (e) =>{
        this.props.handleHelpText(e.target.value)
    }

    selectType = (type) => {
        this.props.selectType(type)
    }

    handleOptions = (val) => {
        this.props.handleOptions(val)
    }
    
    chooseIcon = (type) => {
        switch(type){
            case 'text' : return 'font-colors';
            case 'number' : return 'number';
            case 'telephone' : return 'phone';
            case 'dropdown' : return 'down-circle';
            case 'textarea' : return 'align-left';
            case 'date' : return 'calendar';
            case 'url' : return 'global';
            default : break;
        }
    }

    render() {
        let typeArray  = ['Text','Textarea','Number','Telephone','Url','Date','Dropdown']
        let {selectedType, helpText, fieldName, options} = this.props

        return (
            <React.Fragment>
                <Row gutter={16}>
                    <Col span={16}>
                        <label>Field Name</label>
                        <Typography.Text type="danger" className='ml-5'>*</Typography.Text>
                        <Input size='large' onChange={this.handleName} value={fieldName} maxLength={25} placeholder='Ex: Name, Age' allowClear className='custom-input mt-10' />
                    </Col>
                    <Col span={8}>
                        <label>Field Type</label>
                        <Typography.Text type="danger" className='ml-5'>*</Typography.Text>
                        <Select
                            getPopupContainer={(e)=>e.parentNode}
                            key         = {selectedType}
                            className   = 'custom-select fw mt-10'
                            size        = 'large'
                            placeholder = 'Select Type'
                            defaultValue= {selectedType}
                            disabled
                            // onChange    = {this.selectType} 
                            // allowClear
                        >
                            {
                                typeArray.map((type,index)=>{
                                    return  <Select.Option key={index} value={type.toLowerCase()}>
                                                <Icon type={this.chooseIcon(type.toLowerCase())} className='mr-5' />
                                                {type}
                                            </Select.Option>
                                })
                            }
                        </Select>
                    </Col>
                </Row>
                <Row className='mt-20'>
                    <label>Help Text</label>
                    <Input size='large' onChange={this.handleHelpText} value={helpText} maxLength={40} placeholder='Ex: Enter your name' allowClear className='custom-input mt-10' />
                </Row>
                {
                    (selectedType && selectedType === 'dropdown') &&
                        <Row className='mt-20'>
                            <label>Add Options</label>
                            <Select
                                getPopupContainer={(e)=>e.parentNode}
                                mode            = 'tags'
                                className       = 'custom-select fw mt-10'
                                size            = 'large'
                                onChange        = {this.handleOptions}
                                placeholder     = 'Enter to add options'
                                defaultValue    = {options}
                                allowClear
                                filterOption    = {(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                dropdownRender  = {menu => (
                                    <div>
                                        {menu}
                                        <Divider style={{ margin: '4px 0' }} />
                                        <div style={{ padding: '8px', cursor: 'pointer' }}>
                                            <Icon type="plus" /> Press enter to add options
                                        </div>
                                    </div>
                                )}
                            >

                            </Select>
                        </Row>
                }
            </React.Fragment>
        )
    }
}