import { put, takeLatest, takeLeading, fork, call } from 'redux-saga/effects';
import { message } from 'antd';
import axios from 'axios';
import appUrl from '../../constants/AppUrl';
import TYPES from './CategoriesActionTypes';
import {
  fetchCategoriesSuccess
} from './CategoriesActions';

const fetchCategoriesRequest = () => {
  return axios({
    method: 'GET',
    url: `${appUrl.CATEGORIES}/`,
  })
}

function* fetchCategories(action) {
  try {
    const response = yield call(fetchCategoriesRequest);
    if (!response.data.meta || response.data.meta.status !== 200) {
      message.error('Error fetching categories.');
    } else {
      const categories = response.data.data;
      yield put(fetchCategoriesSuccess(categories.reduce((result, category) => {
        result[category._id] = category;
        return result;
      }, {})));
    }
  } catch(error) {
    message.error('Error fetching categories.');
    console.log(error);
  }
}

function* watchCategories() {
  yield takeLeading(TYPES.FETCH_CATEGORIES, fetchCategories)
}

export default function* categoriesSagas() {
  yield fork(watchCategories);
}
