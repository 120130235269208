import TYPES from "./ProjectActionTypes";

const fetchProjects = () => {
  return {
    type: TYPES.FETCH_PROJECTS,
  };
}

const fetchProjectsSuccess = (projects) => {
  return {
    type: TYPES.FETCH_PROJECTS_SUCCESS,
    projects: projects
  }
}

const fetchProjectsFailed = (error) => {
  return {
    type: TYPES.FETCH_PROJECTS_FAILED,
    error
  }
}

const fetchProject = (id)  => {
  return {
    type: TYPES.FETCH_PROJECT,
    id
  };
}

const fetchProjectSuccess = (project) => {
  return {
    type: TYPES.FETCH_PROJECT_SUCCESS,
    project
  }
}

const fetchProjectFailed = (error) => {
  return {
    type: TYPES.FETCH_PROJECT_FAILED,
    error
  }
}

const fetchNavigationProjects = () => {
  return {
    type: TYPES.FETCH_NAVIGATION_PROJECTS
  };
}

const fetchNavigationProjectsSuccess = (projects) => {
  return {
    type: TYPES.FETCH_NAVIGATION_PROJECTS_SUCCESS,
    projects
  };
}

const fetchNavigationProjectsPushSuccess = (project) => {
  return {
    type: TYPES.FETCH_NAVIGATION_PROJECTS_PUSH_SUCCESS,
    project
  };
}

const updateProject = (project) => {
  return {
    type: TYPES.UPDATE_PROJECT,
    project
  }
}

const updateProjectTemp = (project) => {
  return {
    type: TYPES.UPDATE_PROJECT_TEMP,
    project
  }
}

const fetchProjectTemplates = (params) => {
  return {
    type: TYPES.FETCH_PROJECT_TEMPLATES,
    payload: Object.assign({}, params, {
      template: true,
      draft: true
    })
  };
}

const fetchProjectTemplatesFailed = (payload) => {
  return {
    type: TYPES.FETCH_PROJECT_TEMPLATES_FAILED,
    payload
  };
}

const fetchProjectTemplatesSuccess = (payload) => {
  return {
    type: TYPES.FETCH_PROJECT_TEMPLATES_SUCCESS,
    payload
  };
}

const createNewProject = (payload) => {
  return {
    type: TYPES.CREATE_NEW_PROJECT,
    payload
  }
}

const createNewProjectSuccess = (payload) => {
  return {
    type: TYPES.CREATE_NEW_PROJECT_SUCCESS,
    payload
  }
}

const createNewProjectFailed = (payload) => {
  return {
    type: TYPES.CREATE_NEW_PROJECT_FAILED,
    payload
  }
}

const createNewProjectApp = (payload) => {
  return {
    type: TYPES.CREATE_NEW_PROJECT_APP,
    payload
  }
}

const createNewProjectAppSuccess = (payload) => {
  return {
    type: TYPES.CREATE_NEW_PROJECT_APP_SUCCESS,
    payload
  }
}

const createNewProjectAppFailed = (payload) => {
  return {
    type: TYPES.CREATE_NEW_PROJECT_APP_FAILED,
    payload
  }
}

const createTask = (task, dummyId) => {
  return {
    type: TYPES.CREATE_PROJECT_TASK,
    task,
    dummyId
  };
}

const createTaskFailed = (error) => {
  return {
    type: TYPES.CREATE_PROJECT_TASK_FAILED,
    error
  };
}

const createTaskSuccess = (task, dummyId) => {
  return {
    type: TYPES.CREATE_PROJECT_TASK_SUCCESS,
    task,
    dummyId
  };
}

const updateProjectFailed = (error) => {
  return {
    type: TYPES.UPDATE_PROJECT_FAILED,
    error,
  }
}

const updateProjectSuccess = (id) => {
  return {
    type: TYPES.UPDATE_PROJECT_SUCCESS,
    id
  }
}

const deleteProject = (id) => {
  return {
    type: TYPES.DELETE_PROJECT,
    id
  };
}

const deleteProjectSuccess = (id) => {
  return {
    type: TYPES.DELETE_PROJECT_SUCCESS,
    id,
  };
}

const removeProject = (id) => {
  return {
    type: TYPES.REMOVE_PROJECT,
    id
  }
}

const fetchAllProjects = () => {
  return {
    type: TYPES.FETCH_ALL_PROJECTS,
  };
}

const fetchMarketplaceProjects = (params) => {
  return {
    type: TYPES.FETCH_MARKETPLACE_PROJECTS,
    params
  };
}

const fetchMarketplaceProjectsSuccess = (apps) => {
  return {
    type: TYPES.FETCH_MARKETPLACE_PROJECTS_SUCCESS,
    apps
  };
}

export {
  fetchProject,
  fetchProjectFailed,
  fetchProjectSuccess,
  fetchProjects,
  fetchProjectsFailed,
  fetchProjectsSuccess,
  updateProject,
  updateProjectTemp,
  fetchProjectTemplates,
  fetchProjectTemplatesFailed,
  fetchProjectTemplatesSuccess,
  createNewProject,
  createNewProjectFailed,
  createNewProjectSuccess,
  createNewProjectApp,
  createNewProjectAppFailed,
  createNewProjectAppSuccess,
  createTask,
  createTaskFailed,
  createTaskSuccess,
  updateProjectFailed,
  updateProjectSuccess,
  fetchNavigationProjects,
  fetchNavigationProjectsSuccess,
  fetchNavigationProjectsPushSuccess,
  deleteProject,
  deleteProjectSuccess,
  removeProject,
  fetchAllProjects,
  fetchMarketplaceProjects,
  fetchMarketplaceProjectsSuccess,
};
