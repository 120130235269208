import React from 'react';
import {Table, Input, Button, Icon, Spin, Empty, Row} from 'antd';
import {object} from "prop-types";

class EntriesList extends React.Component {
    state = {
        searchText: '',
        selectedRowKeys: [],
        showDelete: false
    };

    onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
        this.props.selectCheckBox(selectedRowKeys)
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{width: 188, marginBottom: 8, display: 'block'}}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{width: 90, marginRight: 8}}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{width: 90}}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{color: filtered ? '#1890ff' : undefined}}/>
        ),
        onFilter: (value, record) =>
                record['fields'][dataIndex] && record['fields'][dataIndex].value && record['fields'][dataIndex].value
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                this.searchInput.select()
            }
        },
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({searchText: selectedKeys[0]});
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({searchText: ''});
    };


    render() {
        let {data, record, paginationSize} = this.props;
        let fixedName = '';
        if (!record) {
            return <Row type={'flex'} align={'middle'} justify={'center'} style={{minHeight:'70vh'}}><Spin size={"large"}/></Row>
        }


        if (record && record.data && record.data.length > 0) {
            let array = [];
            record.data.map(item => {

                if(item.attributes.fields && Object.keys(item.attributes.fields).length > 4){
                    fixedName = 'left';
                }
                array.push({
                    id: item.id,
                    key:item.id,
                    name: item.attributes.name,
                    description:item.attributes.description,
                    fields: data.attributes.fields && Object.keys(data.attributes.fields).map(val=>{
                        let dataField = {
                            name: data.attributes.fields[val].name,
                            id:val,
                            dataType: data.attributes.fields[val].dataType,
                            alias_help_text: data.attributes.fields[val].alias_help_text,
                            helpText: data.attributes.fields[val].helpText,
                            alias_name: data.attributes.fields[val].alias_name,
                            options: data.attributes.fields[val].options,
                            mandatory: data.attributes.fields[val].mandatory,
                        }
                        if(item.attributes.fields && item.attributes.fields.hasOwnProperty(val)){
                                return {...dataField, value: item.attributes.fields[val].value.row_id}
                            } else {
                                return {...dataField, value: null}
                            }
                    })
            })});

            let columns = [
                // {
                //     title: 'Name',
                //     key:'name',
                //     width: '200px',
                //     fixed: fixedName,
                //     render: (text, list) => {
                //         return <label>{list.name}</label>
                //     },
                //     ...this.getColumnSearchProps('name'),
                // },
                // {
                //     title: 'Description',
                //     key:'description',
                //     width: '200px',
                //     render: (text, list) => {
                //         return <label>{list.description}</label>
                //     },
                //     ...this.getColumnSearchProps('description'),
                // },
            ];
            // console.log(array)
            let con = 0, width = null;
            array.map(item => {
                if(item.fields && con === 0){
                    Object.keys(item.fields).map((val, index) => {
                        let field = item.fields && item.fields[val];
                        width = item.fields && item.fields.length;
                        columns.push({
                            title: field.name,
                            key: field.name,
                           //dataIndex: item,
                            width: '200px',
                            render: (text, list) => {
                                let value = list.fields && list.fields[val] && list.fields[val].value;
                                if(Array.isArray(value)){
                                    value = value.toString()
                                }
                                return <label>{value}</label>
                            },
                            ...this.getColumnSearchProps(index)
                        })
                    });
                    con = 1;
                }
            })


            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                columnWidth: 50,
                onChange: this.onSelectChange,
                hideDefaultSelections: true,
            };

            return <Table rowSelection={rowSelection} style={{marginTop: '30px'}} columns={columns} dataSource={array}
                          pagination={{pageSize: paginationSize}} scroll={{ x: (width * 200)+ 50, y: '100%' }}
                          onRow={(record, rowIndex) => {
                              return {
                                  onClick: event => {this.props.select(record)}, // click row
                                  // onDoubleClick: event => {}, // double click row
                                  // onContextMenu: event => {}, // right button click row
                                  // onMouseEnter: event => {}, // mouse enter row
                                  // onMouseLeave: event => {}, // mouse leave row
                              };
                          }}
            />;
        } else {
            return <Empty description='No record found'/>
        }


    }
}


export default EntriesList