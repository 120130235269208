import { put, takeLatest, takeLeading, fork, call } from 'redux-saga/effects';
import { message } from 'antd';
import axios from 'axios';
import appUrl from '../../constants/AppUrl';
import TYPES from './ProjectActionTypes';
import {
  fetchProjectFailed,
  fetchProjectSuccess,
  fetchProjectsFailed,
  fetchProjectsSuccess,
  fetchProjectTemplatesFailed,
  fetchProjectTemplatesSuccess,
  createNewProjectFailed,
  createNewProjectSuccess,
  createNewProjectAppFailed,
  createNewProjectAppSuccess,
  createTaskSuccess,
  createTaskFailed,
  updateProjectFailed,
  updateProjectSuccess,
  fetchNavigationProjectsSuccess,
  fetchNavigationProjectsPushSuccess,
  deleteProjectSuccess,
  fetchMarketplaceProjectsSuccess,
} from './ProjectActions';
import { fetchMarketplaceAppsSuccess } from '../../marketplaceApp/duck/MarketplaceAppActions';

const fetchProjectRequest = (id) => {
  return axios({
    method: 'GET',
    url: `${appUrl.PROJECTS_URL}/${id}`,
  })
}

function* fetchProject(action) {
  try {
    const response = yield call(fetchProjectRequest, action.id);
    if (!response.data.meta || response.data.meta.status !== 200) {
      message.error('Error loading project.');
      yield put(fetchProjectFailed(response.data))
    } else {
      const project = response.data.data;
      yield put(fetchProjectsSuccess({[project._id]: project}));
      yield put(fetchProjectSuccess(project._id));
    }
  } catch(error) {
    message.error('Error loading project.');
    console.log(error);
  }
}

const fetchProjectsRequest = (action) => {
  return axios({
    method: 'GET',
    url: appUrl.PROJECTS_URL,
    params: {
      limit: action.limit || 30
    }
  });
}

function* fetchProjects(action) {
  try {
    const response = yield call(fetchProjectsRequest, action);
    if (!response.data.meta || response.data.meta.status !== 200) {
      message.error('Error loading projects.');
      yield put(fetchProjectsFailed(response.data))
    } else {
      const projects = response.data.data;
      yield put(fetchProjectsSuccess(projects.reduce((result, project) => {
        result[project._id] = project;
        return result;
      }, {})));
    }
  } catch(error) {
    message.error('Error loading projects.');
    console.log(error);
  }
}

function* fetchAllProjects() {
  try {
    const response = yield call(fetchProjectsRequest, {limit: 50});
    if (!response.data.meta || response.data.meta.status !== 200) {
      message.error('Error loading projects.');
      yield put(fetchProjectsFailed(response.data))
    } else {
      const projects = response.data.data;
      yield put(fetchProjectsSuccess(projects.reduce((result, project) => {
        result[project._id] = project;
        return result;
      }, {})));
    }
  } catch(error) {
    message.error('Error loading projects.');
    console.log(error);
  }
}

function* fetchNavigationProjects(action) {
  try {
    const response = yield call(fetchProjectsRequest, action);
    if (!response.data.meta || response.data.meta.status !== 200) {
      message.error('Error loading projects.');
      yield put(fetchProjectsFailed(response.data))
    } else {
      const projects = response.data.data;
      yield put(fetchProjectsSuccess(projects.reduce((result, project) => {
        result[project._id] = project;
        return result;
      }, {})));
      yield put (fetchNavigationProjectsSuccess(projects.map(project => project._id)));
    }
  } catch(error) {
    message.error('Error loading projects.');
    console.log(error);
  }
}

const updateProjectRequest = (project) => {
  return axios({
    method: 'PUT',
    url: `${appUrl.PROJECTS_URL}/${project._id}`,
    data: {
      data: project
    }
  });
}

function* updateProject(action) {
  try {
    const response = yield call(updateProjectRequest, action.project);
    if (!response.data.meta || response.data.meta.status !== 200) {
      message.error('Error updating project.');
      yield put(updateProjectFailed(response.data))
    } else {
      const project = response.data.data;
      yield put(fetchProjectsSuccess({[project._id]: project}));
      yield put(updateProjectSuccess(project._id));
    }
  } catch(error) {
    message.error('Error updating project.');
    console.log(error);
  }
}

const createNewProjectAppRequest = (data) => {
  return axios({
    //  crossDomain: true,
    //  headers: {'Access-Control-Allow-Origin': '*'},
    method: 'POST',
    url: appUrl.MARKETPLACE_URL,
    data
  })
}

function* createNewProjectApp(action) {
  try {
    const response = yield call(createNewProjectAppRequest, action.payload);
    if (!response.data.meta || response.data.meta.status !== 200) {
      message.error('Error creating project.');
      yield put(createNewProjectAppFailed(response.data))
    } else {
      message.success('Project created!!');
      const project = response.data.data;
      yield put(fetchProjectsSuccess({[project._id]: project}));
      yield put(fetchProjectSuccess(project._id));
    }
  } catch(error) {
    message.error('Error creating new project.');
  }
}

const createNewProjectRequest = (data) => {
  return axios({
    //  crossDomain: true,
    //  headers: {'Access-Control-Allow-Origin': '*'},
    method: 'POST',
    url: appUrl.PROJECTS_URL,
    data
  })
}

function* createNewProject(action) {
  try {
    const response = yield call(createNewProjectRequest, action.payload);
    if (!response.data.meta || response.data.meta.status !== 200) {
      message.error('Error creating project.');
      yield put(createNewProjectFailed(response.data))
    } else {
      message.success('Project created!!');
      const project = response.data.data;
      yield put(fetchProjectsSuccess({[project._id]: project}));
      yield put(fetchProjectSuccess(project._id));
    }
  } catch(error) {
    message.error('Error creating new project.');
  }
}

const fetchProjectTemplatesRequest = (params) => {
  return axios({
    //  crossDomain: true,
    //  headers: {'Access-Control-Allow-Origin': '*'},
    method: 'GET',
    url: appUrl.PROJECTS_URL,
    params: {
        ...params,
        limit: params.limit || 30
    }
  })
}

function* fetchProjectTemplates(action) {
  try {
    const response = yield call(fetchProjectTemplatesRequest, {...action.payload, limit: 100});
    if (!response.data.meta || response.data.meta.status !== 200) {
      message.error('Error fetching project templates.');
      yield put(fetchProjectTemplatesFailed(response.data))
    } else {
      yield put(fetchProjectTemplatesSuccess(response.data));
    }
  } catch(error) {
    message.error('Error fetching project templates.');
    console.log(error);
  }
}

const createTaskRequest = (data) => {
  return axios({
    //  crossDomain: true,
    //  headers: {'Access-Control-Allow-Origin': '*'},
    method: 'POST',
    url: appUrl.TASK_URL,
    data
  })
}

function* createTask(action) {
  const response = yield call(createTaskRequest, action.task);
  if (!response.data.meta || response.data.meta.status !== 200) {
    message.error('Task could not be created please try again!');
    yield put(createTaskFailed(response.data))
  } else {
    yield put(createTaskSuccess(response.data.data, action.dummyId));
  }
}

const deleteProjectRequest = (id) => {
  return axios({
    method: 'DELETE',
    url: `${appUrl.PROJECTS_URL}/${id}`
  })
}

function* deleteProject(action) {
  const response = yield call(deleteProjectRequest, action.id);
  if (!response.data.meta || response.data.meta.status !== 200) {
    message.error('Project could not be deleted, please try again later.');
  } else {
    message.success('Project successfully deleted!');
    yield put(deleteProjectSuccess(action.id));
  }
}

const fetchMarketplaceProjectsRequest = (params) => {
  return axios({
    method: 'GET',
    url: `${appUrl.MARKETPLACE_URL}?type=Project`
  });
}

function* fetchMarketplaceProjects(action) {
  try {
    const response = yield call(fetchMarketplaceProjectsRequest, action.params);
    if (!response.data.meta || response.data.meta.status !== 200) {
      message.error('Error! Cannot fetch projects from marketplace');
    } else {
      const projects = response.data.data;
      yield put(fetchMarketplaceAppsSuccess(projects.reduce((result, project) => {
        result[project._id] = project;
        return result;
      }, {})))
      yield put(fetchMarketplaceProjectsSuccess(projects.map(project => project._id)));
    }
  } catch(error) {
    message.error('Error! Cannot fetch projects from marketplace');
    console.log(error);
  }
}

function* watchProject() {
  yield takeLeading(TYPES.CREATE_NEW_PROJECT, createNewProject)
  yield takeLeading(TYPES.CREATE_NEW_PROJECT_APP, createNewProjectApp)
  yield takeLeading(TYPES.CREATE_PROJECT_TASK, createTask)
  yield takeLeading(TYPES.DELETE_PROJECT, deleteProject)
  yield takeLeading(TYPES.FETCH_PROJECTS, fetchProjects)
  yield takeLeading(TYPES.FETCH_ALL_PROJECTS, fetchAllProjects)
  yield takeLeading(TYPES.FETCH_NAVIGATION_PROJECTS, fetchNavigationProjects)
  yield takeLatest(TYPES.FETCH_PROJECT, fetchProject)
  yield takeLatest(TYPES.UPDATE_PROJECT, updateProject)
  yield takeLatest(TYPES.FETCH_PROJECT_TEMPLATES, fetchProjectTemplates)
  yield takeLatest(TYPES.FETCH_MARKETPLACE_PROJECTS, fetchMarketplaceProjects)
}

export default function* projectSagas() {
  yield fork(watchProject);
}
