import React from 'react';
import {Row, Button, Input, Select, Drawer, Col} from 'antd';
import {showNotification} from "../../utils/commonFunctions";
import {isvalidEmail, isvalidPhone} from "../../utils/validationFunctions";

let {Option} = Select;
let newPeople = {};
const AddPeople = (props) => {
    function close() {
        props.onCloseModel('addPeople');
        newPeople = {};
    }

    function create() {
        if (newPeople.designation && newPeople.designation.split(' ').join('').length > 0 && newPeople.role_id && newPeople.emails) {
            if (isvalidEmail(newPeople.emails[0])) {
                props.createPeople(newPeople)
                newPeople = {}
            } else {
                if (isvalidEmail(newPeople.emails[0])) {
                    props.createPeople(newPeople)
                    newPeople = {}
                }
            }
        } else {
            if (!newPeople.designation) {
                showNotification('error', `Designation is mandatory`)
            }
            if (newPeople.designation && newPeople.designation.split(' ').join('').length > 0) {
                showNotification('error', `Invalid designation`)
            } else if (!newPeople.emails) {
                showNotification('error', `Email is mandatory`)
            } else if (!newPeople.role_id) {
                showNotification('error', `Role is mandatory`)
            }
        }
    }

    if (props.visible) {
        return (
            <Drawer
                placement="right"
                closable={false}
                width={350}
                onClose={() => props.onCloseModel('addPeople')}
                visible={props.visible}
            >
                <Row type='flex' justify='space-between'>
                    <span style={{color: '#44566C', fontSize: '30px', fontWidth: 'bold', fontLineHeight: 500}}>Add People</span>
                    <Button shape="circle" onClick={() => close()} icon="close"
                            style={{color: '#44566C', backgroundColor: '#F8FAFB', fontSize: '17px'}}/>
                </Row>
                {/*<Col style={{marginTop: '20px'}}>*/}
                {/*    <span style={{color: '#44566C'}}>Name<sup style={{fontSize:12, color: 'red', top: 0}}> *</sup></span>*/}
                {/*    <Input*/}
                {/*        placeholder="Enter name"*/}
                {/*        onChange={(e) => newPeople.name = e.target.value}*/}
                {/*        style={{backgroundColor: '#F8FAFB', marginTop: '5px'}}*/}
                {/*    />*/}
                {/*</Col>*/}
                <Col style={{marginTop: '20px'}}>
                    <span style={{color: '#44566C'}}>Email<sup
                        style={{fontSize: 12, color: 'red', top: 0}}> *</sup></span>
                    <Input
                        placeholder="eg@mail.com"
                        onChange={(e) => newPeople.emails = [e.target.value]}
                        className='custom-input mt-5'
                        size='large'
                    />
                </Col>
                <Col style={{marginTop: '20px'}}>
                    <span style={{color: '#44566C'}}>Designation<sup
                        style={{fontSize: 12, color: 'red', top: 0}}> *</sup></span>
                    <Input
                        placeholder="Designer, Developer etc..."
                        maxLength={35}
                        onChange={(e) => newPeople.designation = e.target.value}
                        className='custom-input mt-5'
                        size='large'
                    />
                </Col>
                <Col style={{marginTop: '20px'}}>
                    <span style={{color: '#44566C'}}>Role<sup
                        style={{fontSize: 12, color: 'red', top: 0}}> *</sup></span><br/>
                    <Select
                        getPopupContainer={(e) => e.parentNode}
                        className='custom-select fw mt-5'
                        size='large'
                        onChange={(e) => newPeople.role_id = e}
                        placeholder="Eg: Admin, Manager etc...">
                        {/*<Option value="super_admin">Super Administrator</Option>*/}
                        <Option value="admin">Administrator</Option>
                        {/*<Option value="manager">Manager</Option>*/}
                        <Option value="member">Member</Option>
                        {/*<Option value="free_lancel">Freelancer</Option>*/}
                        {/*<Option value="market_place">Market Place</Option>*/}
                    </Select>
                </Col>
                <Col style={{marginTop: '20px'}}>
                    <span style={{color: '#44566C'}}>Team</span>
                    <Select
                        getPopupContainer={(e) => e.parentNode}
                        className='custom-select fw mt-5'
                        size='large'
                        mode="multiple"
                        showSearch
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(e) => newPeople.teams = e}
                        placeholder="Select teams..."
                    >
                        {props.team && props.team.data.length > 0 && props.team.data.map(team => {
                            return (
                                <Option key={team.id}>{team.name}</Option>
                            )
                        })}
                    </Select>
                </Col>
                {/*<Col style={{marginTop: '20px'}}>*/}
                {/*    <span style={{color: '#44566C'}}>Phone Number</span>*/}
                {/*    <Input*/}
                {/*        onChange={(e) => newPeople.phone = e.target.value}*/}
                {/*        placeholder="Eg 00 000 000 0000"*/}
                {/*        style={{backgroundColor: '#F8FAFB', marginTop: '5px'}}*/}
                {/*    />*/}
                {/*</Col>*/}
                <Row style={{marginTop: '20px'}} type='flex' justify='center'>
                    <Button shape="circle" icon="check" style={{marginRight: 0, color: '#067F4C'}}
                            onClick={() => create()}/>
                    <Button shape="circle" icon="close" style={{marginLeft: 10, color: '#FF3D57'}}
                            onClick={() => close()}/>
                </Row>
            </Drawer>
        );
    }
    return null;
}
export default AddPeople;