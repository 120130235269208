import React, {Component} from 'react';
import {Select, Row} from "antd";
import {connect} from "react-redux";
import axios from "axios";
import appUrl from "../../constants/AppUrl";

class ProcessField extends Component {


    componentDidUpdate(prevProps){
        let {selectedField} = this.props;
        // if user clicks on edit graph btn, so fetching selected template for tat first time.
        // will not wrk in cdm as this component gets selected template on update only
        if(!prevProps.selectedField.key){
            // this.props.selectedField.key && this.fetchFields(selectedField)
        }
    }

    fetchFields= (selectedFields, processFields) => {
        console.log('Selected Fields', selectedFields);
        this.props.onSelectField(selectedFields, processFields)
        // axios({
        //     url:appUrl.TEMPLATE_URL + '/' + selectedTemplate.key,
        //     method:'get'
        // }).then(res=>{
        //     console.log('res', res)
        //     this.props.onSelectField(res.data.data)
        // })
    }

    onSelect = (e) => {
        let {processFields} = this.props;
        // console.log('e', e, processFields);
        // this.props.onSelectField()
        let selectedField = processFields.find(d=>d.key === e.key)
        this.fetchFields(selectedField, processFields)
    }

    render() {
        let {processFields, selectedField,rowStyle} = this.props;
        processFields = processFields.map(field=>{
            return  <Select.Option key={field.key} value={field.key}>{field.label}</Select.Option>
        })
        return (
            <Row style={rowStyle}>
                <label className='f15' style={{color:'#8697A8'}}>Form</label>
                <Select 
                    getPopupContainer={(e)=>e.parentNode}
                    className   = 'fw custom-select' 
                    placeholder = 'Select forms'
                    value       = {selectedField}
                    labelInValue 
                    size        = 'large' 
                    style       = {{marginTop:'12px'}}
                    onSelect    = {this.onSelect}
                    description = 'hello'
                >
                    {processFields}
                </Select>
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        state
    }
}

export default connect(mapStateToProps)(ProcessField);

