import jwtDecode from "jwt-decode";
import {getUrlData} from './getParams'
export default function isUserAuthenticated () {
    let token = localStorage.getItem('jwt')
    if(window.location.pathname === '/admin'){
        let secret_code = getUrlData();
        if(secret_code) localStorage.setItem('jwt',secret_code);
        token = secret_code
    }
    if(token){
        let user = jwtDecode(token);
        let dateNow = new Date();

        if (user.exp > dateNow.getTime()/1000 && (user.user.role_id === 'admin' || user.user.role_id === 'super_admin')) {
            return true;
        }
    }
    return false;
}
