import React from 'react'

export default function Label(props) {
    let {name, mandatory} = props
    return (
        <label>
            {name}
            {
                mandatory &&
                    <sup>*</sup>
            }
        </label>
    )
}
