import React from 'react';
import {
    Row,
    Button,
    Input,
    Select,
    Col,
    Modal
} from 'antd';
import {showNotification} from "../../utils/commonFunctions";
import FormFields from '../form/formFields'

const { TextArea } = Input;
let inputData = {};
let count = 0;

class viewRecord extends React.Component {
    // setup state
    state = {
        loading: false
    }

    updateRecord = () => {
        inputData.fields && Object.keys(inputData.fields).map(key=>{
            inputData.fields[key] = {value: inputData.fields[key].value && inputData.fields[key].value.row_id ? inputData.fields[key].value : {row_id: inputData.fields[key].value}}
        });
        // if(inputData.name === undefined || ''){
            let {data} = this.props;
            inputData.name = data && data.name;
        // }
        this.setState({loading: true});
        // setTimeout(()=>{
            this.props.update(this.props.data.id, inputData);
            this.setState({loading: false});
        // }, 2000)
    };

    setFieldValue = (info) => {
        inputData.fields = {...inputData.fields, ...info.fields};
    };

    close = () => {
        inputData = {
            name: undefined,
            descriptions: undefined,
            members: []
        };
        count = count - 1;
        this.props.onCloseModel()
    };

    render(){
        let {data} = this.props

        return <Modal
            visible={this.props.visible}
            footer={null}
            key={count}
            width={'60%'}
            bodyStyle={{minHeight: '500px'}}
            closable={false}
        >
            <Row type='flex' justify='space-between' key={count}>
                <span style={{color: '#44566C', fontSize: '30px', fontWeight: 'bold'}}>Update Record</span>
                <Button onClick={()=>this.close()} shape="circle"  icon="close" style={{color: '#44566C', backgroundColor:'#F8FAFB', fontSize: '17px'}}/>
            </Row>
            {/*<Col style={{marginTop: '20px'}}>*/}
            {/*    <span style={{color: '#44566C'}}>Record Name</span>*/}
            {/*    <Input*/}
            {/*        onChange={(e)=>inputData.name = e.target.value}*/}
            {/*        className='custom-input'*/}
            {/*        defaultValue={data && data.name}*/}
            {/*        style={{backgroundColor: '#F8FAFB', marginTop: '5px'}}*/}
            {/*    />*/}
            {/*</Col>*/}
            {/*<Col style={{marginTop: '20px'}}>*/}
            {/*    <span style={{color: '#44566C'}}>Descriptions</span>*/}
            {/*    <TextArea*/}
            {/*        className='custom-input'*/}
            {/*        defaultValue={data && data.description}*/}
            {/*        onChange={(e)=>inputData.description = e.target.value}*/}
            {/*        style={{backgroundColor: '#F8FAFB', marginTop: '5px'}}*/}
            {/*    />*/}
            {/*</Col>*/}
            {data &&
            <FormFields
                sections={this.props.dataSet.attributes.sections}
                fields={this.props.dataSet.attributes.fields}
                activeDataset={data}
                updateRecordFields={this.setFieldValue}
            />
            }
            <Row style={{position:'absolute', bottom:0, right: 0, height: 50, width:'100%', background:'#F8FAFB'}}>
                <Row style={{position:'absolute', bottom:10, right: 10}}>
                    <Button onClick={this.close} style={{marginRight: '20px'}} >Cancel</Button>
                    <Button loading={this.state.loading} onClick={this.updateRecord} type="primary">Update</Button>
                </Row>
            </Row>
        </Modal>
    }
}
export default viewRecord;
