import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import {Row, Icon, Button, Steps, Col, Typography, Input } from 'antd'

const title = {fontSize: '20px', lineHeight: '23px', color: '#44566C',fontWeight:'bold'}
const desc  = {fontSize: '12px', lineHeight: '22px', color: '#8798A8'}
const headerStyle = {
    position: 'fixed',
    top: '64px',
    background: '#F8FAFB',
    zIndex: '100',
    left: '0',
    padding: '10px 40px',
    right: '0',
    borderBottom: '0.7px solid #D9DDE1',
}

export default class Header extends Component {
    
    state = {
        showNameInput : false,
        processName   : this.props.name ? this.props.name : '',
        showDescInput : false,
        descName      : this.props.description ? this.props.description : ''
    }

    componentDidUpdate(prevProps){
        if(this.props.name !== prevProps.name){
            this.setState({processName: this.props.name})
        }
        if(this.props.description !== prevProps.description){
            this.setState({descName: this.props.description})
        }
    }

    onChangeOfStep = (current) => {
        this.props.onChangeOfStep(current);
    }

    toggleNameInput = (val) => {
        this.setState({showNameInput: val})
    }

    handleName = (e) => {
        this.setState({processName: e.target.value})
    }

    updateName = () => {
        if(this.state.processName.trim() === ''){
            this.setState({processName: this.props.name})
        }
        this.setState({showNameInput: false})
    }
    
    toggleDescInput = (val) => {
        this.setState({showDescInput: val})
    }

    handleDesc = (e) => {
        this.setState({descName: e.target.value})
    }

    updateDesc = () => {
        this.setState({showDescInput: false})
    }

    render() {
        const {loader, isProject, draftLoading, current} = this.props;
        const {processName, descName} = this.state;
        return (
            <Row type='flex' justify='space-between' align='middle' style={headerStyle}>
                <Col span={!isProject ? 8 : 20}>
                    <Typography.Paragraph ellipsis style={{margin:0}}>
                        {
                            this.state.showNameInput
                            ?
                                <Input size='small' autoFocus value={processName} onChange={this.handleName} onBlur={this.updateName} />
                            :
                            <span style={title} onClick={e=>this.toggleNameInput(true)}>
                                {processName}
                            </span>
                        }
                        <br/>
                        {
                            this.state.showDescInput
                            ?
                                <Input size='small' autoFocus value={descName} onChange={this.handleDesc} onBlur={this.updateDesc} placeholder='Add description' />
                            :
                            <span style={desc} onClick={e=>this.toggleDescInput(true)}>
                                {descName || 'Add description'}
                            </span>
                        }
                    </Typography.Paragraph>
                </Col>
                {
                    !isProject &&
                        <Col span={8}>
                            <Row style={{height:'35px',margin:'0 80px'}} type='flex' justify='center'>
                                <Steps progressDot current={current} onChange={this.onChangeOfStep}>
                                    <Steps.Step title="Form"/>
                                    <Steps.Step title="Workflow"/>
                                </Steps>
                            </Row>
                        </Col>
                }
                <Col span={!isProject ? 8 : 4}>
                    <Row type='flex' justify='end'>
                        {
                            isProject
                            ?
                                <Link to={`/app/projects/${this.props.projectId}`}>
                                    <Button type='danger' className='mr-10'>
                                        Cancel
                                    </Button>
                                </Link>
                            :
                                <Link to='/app/processes'>
                                    <Button type='danger' className='mr-10'>
                                        Cancel
                                    </Button>
                                </Link>
                        }
                        {!isProject && <Button type='default' loading={draftLoading} className='mr-10' onClick={e=>this.props.saveAsDraft(processName, descName)}>Save Draft</Button>}                    
                        {
                            !isProject && !current
                            ?
                                <Button type='primary' loading={loader} onClick={e=>this.onChangeOfStep(1)}>
                                    Next
                                    <Icon type='right' className='f-12' />
                                </Button>
                            :
                                <Button type='primary' loading={loader} onClick={e=>this.props.onPublish(processName, descName)}>Publish</Button>
                        }
                        {/* {isProject && <Button type='primary' loading={loader} onClick={e=>this.props.onPublish()}>Publish</Button>} */}
                    </Row>
                </Col>
            </Row>
        )
    }
}
