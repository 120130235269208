import React, { Component } from 'react'
import {Row, Col, Radio, Select, Input, Mentions} from 'antd'

export default class SMS extends Component {

    state = {
        recipient   : this.props.to ? this.props.to : [],
        text        : this.props.text ? this.props.text : ''
    }

    handleSmsRecipient = (val) => {
        console.log(val)
        this.setState({to: val})
        this.props.updateSmsRecipient(val)
    }

    handleSmsText = (value) => {
        this.setState({text: value})
        this.props.updateSmsText(value)
    }

    onChange = (value) => {
        console.log('Change:', value);
    }

    render() {
        const {status, toggleCustom, templateTags} = this.props
        return (
            <Row>
                <Radio.Group name="smsgroup" onChange={toggleCustom} defaultValue={status} className='mb-20'>
                    <Radio value='default'>Default</Radio>
                    <Radio value='custom'>Custom</Radio>
                </Radio.Group>
                <Row gutter={16}>
                    <Row className='ml-10 mb-5'>Recepient</Row>
                    <Col span={24}>
                        <Select
                            mode        = 'multiple'
                            className   = 'custom-select fw'
                            size        = 'large'
                            placeholder = 'Select recipient list'
                            disabled    = {status === 'default' ? true : false}
                            defaultValue= {this.state.recipient}
                            onChange    =  {this.handleSmsRecipient}
                        >
                            {
                                templateTags.map(tag => {
                                    if(tag.type === 'telephone'){
                                        return <Select.Option key={tag.name} value={tag.id ? tag.id : tag.name}>{tag.name}</Select.Option>
                                    }
                                })
                            }
                        </Select>
                    </Col>
                </Row>
                <Row className='mt-15'>
                    <Row className='mb-5'>Message</Row>
                    <Mentions
                        className   = 'custom-mention'
                        placeholder = 'Enter message and use # to mention tags'
                        disabled    = {status === 'default' ? true : false}
                        prefix      = {['#']}
                        value       = {this.state.text}
                        onChange    = {this.handleSmsText}
                        rows        = '15'
                    >
                        {
                            templateTags.map(tag => {
                                return <Mentions.Option value={tag.id ? tag.name+'_'+tag.id : tag.name} key={tag.id ? tag.id : tag.name}>{tag.id ? tag.name+'_'+tag.id : tag.name}</Mentions.Option>
                            })
                        }
                    </Mentions>
                    {/* <Input.TextArea
                        className = 'custom-input'
                        rows = '8'
                        disabled = {status === 'default' ? true : false}
                        value = {this.state.text}
                        onChange= {this.handleSmsText}
                        placeholder = 'Enter message'
                    >
                    </Input.TextArea> */}
                </Row>

            </Row>
        )
    }
}
